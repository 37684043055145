import { userAuthService } from '@/common/services/auth.service';
import router from '@/router';
import TokenService from "@/common/services/token";
import api from "@/common/services/api";
import Vue from "vue";
import store from "@/store";
import token from "@/common/services/token";

const user = JSON.parse(localStorage.getItem('userData'));

const state = () => ({
    status: {
        loggedIn: user ? true : null,
    },
    userData: null,
    accessToken: null,
    errors: null,
    registerLoading: false,
    authDone: false,
    resendVerificationLoading: false,
    resendLinkDisable: false,
    accountDeleted: false,
    accountDeleting: false,
    modalBannedAlreadyShowed: false,
    registered: false,
})

const getters = {
    userData: state => state.userData ? state.userData : JSON.parse(localStorage.getItem('userData')),
    loggedIn: state => state.status.loggedIn,
    accessToken: state => state.accessToken,
    errors: state => state.errors,
    registerLoading: state => state.registerLoading,
    authDone: state => state.authDone,
    resendVerificationLoading: state => state.resendVerificationLoading,
    resendLinkDisable: state => state.resendLinkDisable,
    accountDeleted: state => state.accountDeleted,
    accountDeleting: state => state.accountDeleting,
    modalBannedAlreadyShowed: state => state.modalBannedAlreadyShowed,
    registered: state => state.registered,
};


const actions = {
    login({ dispatch, commit }, { email, password, rememberMe }) {
        commit('loginRequest', { email });
        api.setLocale();
        userAuthService.login(email, password, rememberMe)
            .then(
                success => {
                    commit('loginSuccess', success.data);
                    commit('setUserData', success.data.user);
                    commit('setToken', success.data.token);
                    router.push(`/${success.data.user.loginScreen}`);
                    dispatch('fetchLangAndRestOfData', success.data.user.lang)


                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    commit('loginFailure', error.errors);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit, getters, dispatch, rootGetters }, makeRequest = true) {
        userAuthService.logout();

        api.setHeader();

        if(makeRequest) {
            api.post('/logout').then(
                success => {
                    if(getters.userData.logoutWww) {
                        window.location.replace(`https://${getters.userData.logoutWww}`);
                    }
                    if(rootGetters['chat/currentThread']) {
                        window.Echo.leave(`chat.${rootGetters['chat/currentThread'].id}`);
                    }
                    commit('chat/clearChatDataOnLogout', null, { root: true })
                    commit('clearUserData')
                    commit('logout');
                    router.push({ name: 'auth-login', })
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                });
        } else {
            commit('chat/clearChatDataOnLogout', null, { root: true })
            commit('clearUserData')
            commit('logout');
            router.push({ name: 'auth-login'})
        }

    },

    register({ dispatch, commit, state }, user) {
        commit('registerRequest', user);
        commit('setRegisterLoading', true);
        state.registered = false;
        userAuthService.register(user)
            .then(
                success => {
                    commit('registerSuccess', user);
                    dispatch('alert/success', success, { root: true });
                    state.registered = true;
                },
                error => {
                    state.registered = false;
                    commit('registerFailure', error.errors);
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setRegisterLoading', false);
        });
    },
    authRequest({commit, dispatch}) {
        commit('setAuthDone', false);
        api.post('/user')
            .then(
                success => {
                    if(success !== undefined) {
                        let locale = success.data.user !== undefined ? success.data.user.lang : success.data.lang;
                        commit('setLoggedState', true);
                        commit('setUserData', success.data)
                        dispatch('fetchLangAndRestOfData', locale)
                    } else {
                        window.location.href = '/login'

                        dispatch('logout', false);
                        commit('setLoggedState', false);

                        return;
                    }
                },
                error => {

                }
            ).finally(() => {

            })
    },
    fetchLangAndRestOfData({commit, dispatch}, success) {
        store.dispatch('lang/fetchLangData', success)

        store.dispatch('subscriptions/fetchCurrentSubscriptionData').then(r => {
                store.dispatch('verification/fetchCurrentVerificationData').then(r => {
                    commit('setAuthDone', true);
                })
            }
        );
    },

    resetPasswordRequest({commit, dispatch}, payload) {
        api.setLocale();
        commit('setPasswordResetLoading', true);
        api.post('/reset-password', payload)
            .then(
                success => {
                    router.push({name: 'auth-login'})
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPasswordResetLoading', false);
        })
    },

    updateUserData({commit}, data) {
        commit('setUserData', data);
    },

    resendVerificationEmail({commit, dispatch}, payload) {
        commit('setResendVerificationLoading', true);

        api.setHeader();
        api.post('/email/verification-notification', payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                    commit('setResendLinkDisable', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setResendVerificationLoading', false);
        })
    },

    updateSubscriptionAndDashboard({commit, dispatch}, payload) {
        api.setHeader();
        api.post('/profile/get-data-after-payment', payload)
            .then(
                success => {
                    commit('updateSubscriptionAndDashboard', success.data);
                },
                error => {
                }
            ).finally(() => {
        })
    },

    deleteAccountRequest({state, commit, dispatch}, payload) {
        commit('setAccountDeleting', true);
        commit('setAccountDeleted', false);

        api.setHeader();
        api.post(`/${state.userData.username}/delete-account`, payload)
            .then(
                success => {
                    commit('setAccountDeleted', true);
                    dispatch('logout', false);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setAccountDeleting', false);
        })
    },
}

const mutations = {
    loginRequest(state) {
        state.status = { loggingIn: true };
    },
    loginSuccess(state, data) {
        TokenService.saveToken(data.token, data.expiresAt);
    },
    loginFailure(state, errors) {
        state.status = {};
        state.errors = errors;
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
        state.userData = null;
        state.accessToken = null;
        state.modalBannedAlreadyShowed = false;
    },
    registerRequest(state, user) {
        state.status = { registering: true };
    },
    registerSuccess(state, data) {
        state.registeredSuccessData = data;
    },
    registerFailure(state, error) {
        state.status = {};
        state.errors = error;
    },
    setLoggedState(state, value) {
        state.status = { loggedIn: value };
    },
    setUserData(state, data) {
        localStorage.setItem('userData', JSON.stringify(data));
        state.userData = data;
    },
    setRegisterLoading(state, value) {
        state.registerLoading = value;
    },
    setPasswordForgotLoading(state, value) {
        state.passwordForgotLoading = value;
    },
    setPasswordResetLoading(state, value) {
        state.passwordResetLoading = value;
    },
    setToken(state, token) {
        state.accessToken = token;
    },
    clearUserData(state) {
        state.userData = null;
    },
    setAuthDone(state, value) {
        state.authDone = value;
    },
    setResendVerificationLoading(state, value) {
        state.resendVerificationLoading = value;
    },
    setResendLinkDisable(state, value) {
        state.resendLinkDisable = value;
    },
    setUserVerified(state, value) {
        state.userData.isVerified = value;
    },
    setUserModalDisplayedProfile(state) {
        state.userData.modalDisplayedProfile = true;
    },
    setUserModalDisplayedGallery(state) {
        state.userData.modalDisplayedGallery = true;
    },
    setUserModalDisplayedSettings(state) {
        state.userData.modalDisplayedSettings = true;
    },
    setUserModalDisplayedSearch(state) {
        state.userData.modalDisplayedSearch = true;
    },
    setUserModalDisplayedChat(state) {
        state.userData.modalDisplayedChat = true;
    },
    setUserVerification(state, data) {
        store.state['verification'].currentVerification = data;
    },
    setUserFavFilters(state, data) {
        state.userData.favouriteFilters = data;
    },
    updateRememberedUsers(state, data) {
        state.userData.rememberedUsers = data;
    },
    updateRememberedNames(state, value) {
        state.userData.rememberedNames.push(value);
    },
    cleanRememberedNames(state) {
        Vue.set(state.userData, 'rememberedNames', []);
    },
    cleanRememberedUsers(state) {
        Vue.set(state.userData, 'rememberedUsers', []);
    },
    removeRememberedNames(state, value) {
        state.userData.rememberedNames.splice(state.userData.rememberedNames.findIndex(el => el === value), 1);
    },
    removeRememberedUser(state, value) {
        state.userData.rememberedUsers.splice(state.userData.rememberedUsers.findIndex(el => el.username === value), 1);
    },
    setUserSubscription(state, data) {
        state.userData.subscription = data;
    },
    setAccountDeleted(state, value) {
        state.accountDeleted = value;
    },
    setAccountDeleting(state, value) {
        state.accountDeleting = value;
    },
    updateUserDataDashboardColor(state, value) {
        Vue.set(state.userData, 'dashboardColor', value);
    },
    updateModalBannedAlreadyShown(state, value) {
        state.modalBannedAlreadyShowed = value;
    },
    updateUserBannedStatus(state, data) {
        state.userData.bannedAt = data.bannedAt;
        state.userData.banReason = data.banReason;
        state.userData.isBanned = data.isBanned;
        state.userData.hasFullAccess = data.hasFullAccess;
        state.userData.dashboardColor = data.dashboardColor;
    },
    updateSubscriptionAndDashboard(state, data) {
        store.state['subscriptions'].currentSubscription = data.subscription;
        store.state['verification'].currentVerification = data.verification;
        state.userData.dashboardColors = data.dashboardColors;
    },
    updatePicturesStatus(state, data) {
        state.userData.picturesPending = data.picturesPending
        state.userData.picturesRejected = data.picturesRejected
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};