<template>
  <div class="relative pt-12 lg:pt-0">
<!--    <div class="fixed top-0 left-0 w-full h-screen bg-gradient-to-b from-grayed opacity-50"/>-->
    <div v-scroll-spy="{data: 'div.homepage-selector', offset: 48}">
      <!-- LOGO -->
      <div class="homepage-selector">
        <div ref="topSection"
          class="w-full h-screen flex flex-col justify-center items-center"
        >
          <div class="w-full flex flex-col justify-center items-center">
            <Slogan
              class="font-light opacity-75 lg:opacity-50 pt-16 lg:pt-32"
              text1="LOGO:Slogan1.1"
              text2="LOGO:Slogan1.2"
              :text2-highlighted=true
              text3="LOGO:Slogan1.3"
              desktop-size="2xl"
              :mobile-divider=24
              :uppercase=true
              :space-separated=true
            />
            <Slogan
              class="pb-2 lg:pb-6 pt-6"
              text1="LOGO:Segment1"
              text2="LOGO:Segment2"
              :text2-highlighted="true"
              text3="LOGO:Segment3"
              desktop-size="7xl"
              :mobile-divider=9
            />
            <Slogan
              class="font-light"
              text1="LOGO:Slogan2.1"
              text2="LOGO:Slogan2.2"
              :text2-highlighted="true"
              text3="LOGO:Slogan2.3"
              desktop-size="3xl"
              :mobile-divider=21
              :space-separated=true
              :break-on-mobile=true
            />
          </div>
          <HomepageChevron large dark @click="scrollToSection('chase')"/>
        </div>
      </div>
      <!--  CHASE -->
      <div ref="chase" id="chase"
        class="relative homepage-selector w-full flex flex-col h-screen justify-start items-center"
      >
        <HomepageSectionTitle v-if="!checkIsMobile" center>
          <template v-slot:title1>{{ trans('ABOUT:DatingOnline1') }}&nbsp;{{ trans('ABOUT:DatingOnline2') }}</template>
        </HomepageSectionTitle>
        <HomepageSectionTitle v-if="checkIsMobile" center>
          <template v-slot:title1>{{ trans('ABOUT:DatingOnline1') }}</template>
          <template v-slot:title2>{{ trans('ABOUT:DatingOnline2') }}</template>
        </HomepageSectionTitle>
        <img alt="chase" class="border-b border-t border-grayedDark border-opacity-50 bg-white"
          :src="checkIsMobile?this.themeImages.aboutChaseMobile:this.themeImages.aboutChaseDesktop"
        >
        <HomepageChevron dark @click="scrollToSection('anonymity')"/>
      </div>
      <!--  ANONYMITY -->
      <div ref="anonymity" id="anonymity"
        class="homepage-selector relative w-full flex flex-col justify-center items-center z-30"
      >
        <HomepageSectionTitle v-if="!checkIsMobile" center>
          <template v-slot:title1>{{ trans('ABOUT:DatingNoAnonymity1') }}&nbsp;{{ trans('ABOUT:DatingNoAnonymity2') }}</template>
        </HomepageSectionTitle>
        <HomepageSectionTitle v-if="checkIsMobile" ref="anonymity" id="anonymity" center>
          <template v-slot:title1>{{ trans('ABOUT:DatingNoAnonymity1') }}</template>
          <template v-slot:title2>{{ trans('ABOUT:DatingNoAnonymity2') }}</template>
        </HomepageSectionTitle>
        <HomepageParallax
          no-top-margin-desktop
          no-top-margin-mobile
          no-bottom-margin-desktop
          no-bottom-margin-mobile
          :desktop-height=600
          :class="'parallax-tiptoes'"
        />
        <HomepageParallax
          no-top-margin-desktop
          no-top-margin-mobile
          no-bottom-margin-desktop
          no-bottom-margin-mobile
          :desktop-height=600
          :class="'parallax-roses'"
        />
        <HomepageChevron bottom-margin dark @click="scrollToSection('welcomenot')"/>
        <HomepageParallax
          no-top-margin-desktop
          no-top-margin-mobile
          no-bottom-margin-desktop
          no-bottom-margin-mobile
          :desktop-height=600
          :class="'parallax-table'"
        />
        <HomepageParallax
          no-top-margin-desktop
          no-top-margin-mobile
          no-bottom-margin-desktop
          no-bottom-margin-mobile
          :desktop-height=600
          :class="'parallax-walk'"
        />
<!--        <HomepageParallax-->
<!--          no-top-margin-desktop-->
<!--          no-top-margin-mobile-->
<!--          no-bottom-margin-desktop-->
<!--          no-bottom-margin-mobile-->
<!--          :desktop-height=600-->
<!--          :class="'parallax-come'"-->
<!--        />-->
        <HomepageChevron dark @click="scrollToSection('welcomenot')"/>
      </div>

      <!-- NOT FOR EVERYBODY  -->
      <div ref="welcomenot" id="welcomenot"
        class="homepage-selector w-full flex flex-col justify-center items-center"
      >
        <div class="w-full flex flex-col justify-center items-center">
          <HomepageSectionTitle center>
            <template v-slot:title1>{{ trans('ABOUT:WelcomeNot') }}</template>
          </HomepageSectionTitle>
          <div class="w-full lg:w-800 flex flex-col lg:flex-row gap-16">
            <div class="text-lg flex flex-col w-full lg:w-1/2 gap-3 px-6 lg:px-0 justify-start">
              <p class="text-lg lg:text-2xl mb-3">{{ trans('ABOUT:WelcomeNot1') }}</p>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot2') }}</HomepageLi>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot3') }}</HomepageLi>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot4') }}</HomepageLi>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot5') }}</HomepageLi>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot6') }}</HomepageLi>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot7') }}</HomepageLi>
                <HomepageLi>{{ trans('ABOUT:WelcomeNot8') }}</HomepageLi>
              <p class="text-lg lg:text-2xl mt-3">{{ trans('ABOUT:WelcomeNot9') }}</p>
            </div>

            <div class="w-full lg:w-1/2 flex flex-col gap-12 justify-center items-center px-3 lg:px-0">
              <span class="text-lg lg:text-2xl ">{{ trans('ABOUT:WelcomeNot10') }}</span>
              <Button
                variant="buttonRedOutlinedLarge"
                class="uppercase"
                :to="{name: 'about'}"
                :text="trans('BUTTON:About')"
              />
              <Button
                variant="buttonRedOutlinedLarge"
                class="uppercase"
                :to="{name: 'posts', params: {postCategory: 'hints'}}"
                :text="trans('BUTTON:Hints')"
              />
              <Button
                variant="buttonRedOutlinedLarge"
                class="uppercase"
                :to="{name: 'posts', params: {postCategory: 'faq'}}"
                :text="trans('BUTTON:FAQ')"
              />

            </div>
          </div>
          <HomepageChevron bottom-margin dark @click="scrollToSection('welcome')"/>
        </div>
        <HomepageParallax
          no-top-margin-desktop
          no-top-margin-mobile
          no-bottom-margin-desktop
          no-bottom-margin-mobile
          :desktop-height=600
          :class="'parallax-author'"
        />
      </div>
      <!-- WELCOME -->
      <div ref="welcome" id="welcome"
        class="homepage-selector h-screen w-full flex flex-col justify-center items-center"
      >
        <SectionWelcome/>
      </div>
    </div>
  </div>
</template>

<script>
import 'jquery-parallax.js'
import Button from "@/utils/crud/components/Button";
import Slogan from "@/views/content/components/Homepage/Slogan";
import HomepageChevron from "@/views/content/components/Homepage/HomepageChevron";
import HomepageLi from "@/views/content/components/Homepage/HomepageLi";
import HomepageParallax from "@/views/content/components/Homepage/HomepageParallax";
import HomepageSectionTitle from "@/views/content/components/Homepage/HomepageSectionTitle";
import HomepageSection from "@/views/content/components/Homepage/HomepageSection";
import TextField from "@/utils/crud/components/field-types/Text";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import themeConfig from "@/themeConfig";
import {isAppLaunched} from "@/common/helpers/utils";
import {mapGetters, mapActions} from "vuex";
import SectionWelcome from "@/views/content/static/components/SectionWelcome";

export default {
  name: "Homepage",
  components: {
    Button,
    HomepageChevron,
    Slogan,
    HomepageParallax,
    HomepageSection,
    HomepageSectionTitle,
    HomepageLi,
    TextField,
    SectionWelcome
  },
  data() {
    return {
      email: null,
      themeImages: themeConfig.staticPhotos,
      checkIsMobile: checkIsMobileDevice(),
      isAppLaunched: isAppLaunched(),
      howWeChosen: "1",
      howWeChosen2: "2",
      howToChosen: "1",
      howWeData: {},
      howToData: {},
    }
  },
  watch: {
    langLoaded: {
      immediate: true
    },
  },
  computed: {
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('newsletter', ['newsletterLoading']),
    ...mapGetters('lang', ['langLoaded']),
  },
  methods: {
    ...mapActions('newsletter', ['signToNewsletterRequest']),
    ...mapActions('alert', ['error', 'success']),

    signToNewsletter() {
      this.signToNewsletterRequest({email: this.email})
    },
    scrollToSection(section) {
      let element = document.getElementById(section);
      let headerOffset = 48;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    $('.parallax-tiptoes').parallax({imageSrc: this.themeImages.aboutWideTiptoes,});
    $('.parallax-roses').parallax({imageSrc: this.themeImages.aboutWideRoses});
    $('.parallax-table').parallax({imageSrc: this.themeImages.aboutWideTable});
    $('.parallax-walk').parallax({imageSrc: this.themeImages.aboutWideWalk});
    $('.parallax-author').parallax({imageSrc: this.themeImages.aboutWideAuthor});

    this.$root.$on('scroll-to-top', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    this.$root.$on('scroll-to-welcome', () => {
      console.log('scroll-to-welcome START');
      const welcomeElement = document.getElementById('welcome');
      if (welcomeElement) {
        welcomeElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error('Element with id "welcome" not found.');
      }
      console.log('scroll-to-welcome KONIEC');
    })
  },

  beforeDestroy() {
	  $('.parallax-mirror').remove()

    // $('.parallax-tiptoes').remove()
    // $('.parallax-roses').remove()
    // $('.parallax-table').remove()
    // $('.parallax-walk').remove()
    // $('.parallax-author').remove()
  }
}
</script>

<style lang="scss">
.prlx-img {
  height: 180px;
}

@media (min-width: 1024px) {
  .prlx-img {
    height: 360px;
  }
}

.menu-link {
	&.active-link {
		opacity: 1;
		color: theme('colors.redAccent')
	}
}

</style>