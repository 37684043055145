import api from "@/common/services/api";
import store from "@/store";

const state = () => ({
  loading: false,
  galleryData: null,
  galleryDeleting: false,
  galleryDeleted: false,
  profilePictureLoading: false,
  gallerySourcesArray: [],
  gallerySourcesLoaded: false,
  pictureDeleted: false,
  featuredPictureId: null,
})

// getters
const getters = {
  galleryLoading: state => state.loading,
  galleryData: state => state.galleryData,
  galleryDeleting: state => state.galleryDeleting,
  galleryDeleted: state => state.galleryDeleted,
  profilePictureLoading: state => state.profilePictureLoading,
  gallerySourcesArray: state => state.gallerySourcesArray,
  gallerySourcesLoaded: state => state.gallerySourcesLoaded,
  pictureDeleted: state => state.pictureDeleted,
  featuredPictureId: state => state.featuredPictureId,
};

//actions
const actions = {
  fetchUserGallery: ({ commit, dispatch}, payload = null) => {
    api.setHeader();
    commit('setLoading', true);
    let username;
    if(payload !== null) {
      username = payload;
    } else {
        username = store.getters['account/userData'] ? store.getters['account/userData'].username : JSON.parse(localStorage.getItem('userData')).username;
    }

    api.get(`/pictures/${username}`, '', true)
      .then(
        success => {
          commit('setGalleryData', success.data)
        },
        error => {
          dispatch('alert/error', error, { root: true });
        }
      ).finally(() => {
        commit('setLoading', false);
    });
  },

  deletePhoto: ({commit, dispatch}, payload) => {
    api.setHeader();
    commit('setPictureDeleted', false);
    api.delete(`/pictures/${payload.pictureId}`)
      .then(
        success => {
          commit('removeFromGallery', payload.pictureId);
          commit('account/updatePicturesStatus', success.data.profileData, {root: true});
          dispatch('alert/success', success, { root: true });
          commit('setPictureDeleted', true);
        },
        error => {
          dispatch('alert/error', error, { root: true });
        }
      ).finally(() => {
    });
  },

  clearGalleryRequest: ({commit, dispatch}) => {
    api.setHeader();
    commit('setGalleryDeleting', true)
    commit('setGalleryDeleted', false)
    api.post(`/pictures/clear-gallery`)
      .then(
        success => {
          commit('setGalleryData', success.data.galleryData);
          commit('profile/setProfileData', success.data.profileData, {root: true});
          commit('account/setUserData', success.data.profileData, {root: true});
          dispatch('alert/success', success, { root: true });
          commit('setGalleryDeleted', true)
        },
        error => {
          dispatch('alert/error', error, { root: true });
        }
      ).finally(() => {
        commit('setGalleryDeleting', false)
    });
  },

  setProfilePictureRequest: ({commit, dispatch}, payload) => {
    api.setHeader();
    commit('setProfilePictureLoading', true)
    api.post(`/pictures/set-featured`, payload)
      .then(
        success => {
          commit('setProfilePicture', success.data.pictureId);
          commit('setFeaturedPictureId', success.data.pictureId);
          commit('account/setUserData', success.data.profileData, {root: true});
          dispatch('dashboard/fetchDashboardData', {} , {root: true})
          dispatch('alert/success', success, { root: true });

        },
        error => {
          dispatch('alert/error', error, { root: true });
        }
      ).finally(() => {
      commit('setProfilePictureLoading', false)
    });
  },

}

//mutations
const mutations = {
  setGalleryData (state, data) {
    state.galleryData = data;
  },
  setLoading (state, value) {
    state.loading = value;
  },
  removeFromGallery(state, id) {
    state.galleryData.splice(state.galleryData.findIndex(el => el.id === id), 1)
  },

  addToGallery (state, data) {
    state.galleryData.push(data);
  },

  setGalleryDeleting (state, value) {
    state.galleryDeleting = value;
  },

  setGalleryDeleted (state, value) {
    state.galleryDeleted = value;
  },

  setProfilePictureLoading(state, value) {
    state.profilePictureLoading = value;
  },

  setPictureDeleted(state, value) {
    state.pictureDeleted = value;
  },

  setProfilePicture(state, id) {
    state.galleryData.forEach(el => {
        el.isFeatured = el.id === id;
    });
  },

    setFeaturedPictureId(state, id) {
      state.featuredPictureId = id;
    }
}

export const gallery = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
