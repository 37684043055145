<template>
  <PageContainer>
    <PageTitle>
      <div class="flex flex-row items-center gap-3">
        <div>
          {{ trans('PAGE_TITLE:NewContacts') }}
        </div>
        <VTooltip>
          <span class="flex flex-row items-center">
            <Button
              variant="link"
              @clicked="openBeforeYouStartSearchingModal(true)"
            >
              <base-icon  name="info" width="20" height="20"/>
            </Button>
          </span>
          <template #popper>
            {{ trans('LABEL:BeforeYouStartSearching') }}
          </template>
        </VTooltip>
      </div>
      <BadgeFrame v-if="userData && userData.isBanned">
        <Badge tagName="span" variant="badgeError">
          {{ trans('LABEL:AccountBlocked') }}
        </Badge>
      </BadgeFrame>
    </PageTitle>
    <PageContentWithSidebar>
      <!-- Sidebar START -->
      <Sidebar>
        <t-card class="w-full lg:w-sidebar lg:max-h-search-container overflow-y-scroll">
          <!-- Mobile sidebar START-->
          <div class="block lg:hidden mb-3 bg-white w-full flex flex-row items-center justify-between">
            <Button
              @clicked="changeNav('search')"
              :iconActive="nav.search"
              variant="icon"
              icon="search"
            />
            <Button
              @clicked="changeNav('history')"
              :iconActive="nav.history"
              variant="icon"
              icon="history"
            />
            <div class="flex flex-row items-center relative">
              <Button
                @clicked="changeNav('remembered')"
                :iconActive="nav.remembered"
                variant="icon"
                icon="starFilled"
              />
              <div v-if="userData.rememberedUsers.length > 0" class="absolute right-4 bottom-0"
                @click="changeNav('remembered')"
              >
                <Badge class="mb-3" variant="counterSuccess">
                  {{ userData.rememberedUsers.length > 100 ? '100+' : userData.rememberedUsers.length }}
                </Badge>
              </div>
            </div>
          </div>
          <!-- Desktop sidebar START-->
          <div
              v-if="searchAvailableAt === null"
              class="flex flex-col gap-3"
          >
            <ListHeaderSection
              v-show="!isMobile"
              :text="trans('LABEL:Search')"
              no-counters
              class="lg:mb-1"
            >
              <base-icon name="pin" width="16" height="16"/>
            </ListHeaderSection>

            <Autocomplete
              :disabled="searchAvailableAt !== null || !userData.isProfileComplete || !userData.hasFullAccess"
              :placeholder="trans('PLACEHOLDER:StartTypingCityName')"
              type="location"
              @result-selected="acceptLocation"
              ref="autocomplete"
            />
            <div class="flex w-full flex-row justify-between">
              <Button
                class="text-s"
                :disabled="userSearchLocation === userData.location.id || !userData.hasFullAccess"
                :text="trans('LABEL:SearchUseMyLocation')"
                variant="link"
                @clicked="useMyLocation(userData)"
              />
              <Button
                class="text-s"
                :disabled="!userData.hasFullAccess"
                :text="trans('BUTTON:Map')"
                variant="link"
                @clicked="openMapModal(userData, userSearchLocation)"
              />
              <Button
                :loading="profilesSearching"
                :disabled="userSearchLocation === null || !userData.hasFullAccess"
                :text="trans('BUTTON:Search')"
                @clicked="(profilesToShowCount > 0 && searchAvailableAt === null)
                || userData.rememberedUsers.length > 0 ?
                showStartNewSearchAlertModal(true) : searchProfiles()"
              />
            </div>
          </div>
          <div v-else class="flex flex-col gap-1 text-baser">
            <ListHeaderSection
              v-show="!isMobile"
              :text="trans('LABEL:Search')"
              no-counters
              class="lg:mb-1"
            >
              <base-icon name="pin" width="16" height="16"/>
            </ListHeaderSection>
            <div class="flex flex-row w-full justify-center">{{ trans('LABEL:SearchAvailableIn') }}:</div>
            <div class="flex flex-row w-full justify-center">
              <Countdown
                :end-date="searchAvailableAt"
                @ended="clearTimer"
                class="font-semibold text-redAccent"
              />
            </div>
          </div>
          <SearchSidebar
            :search-allowed = "searchAvailableAt === null"
            @location-selected="locationSelected"
            :current-nav="nav"
            :highlight-first-location="profilesToShowCount > 0 || userData.rememberedUsers.length > 0"
            :current-location=userSearchLocation
          />
        </t-card>
      </Sidebar>

      <!-- Page content -->
      <div class="w-full flex flex-col gap-y-3 lg:gap-y-6">
        <!--	Loading spinner    -->
        <div v-show="usersLoading || !userData">
          <Loader class="w-full lg:w-main-container lg:h-sidebar"
            :class="getHeightOfScreenWidth(1)"
          />
        </div>
        <!-- Profiles to show count - on Mobile above the screen -->
        <ProfilesCounter v-show="!usersLoading && isMobile && profilesToShowMessage !== null && userData.hasFullAccess"
          :message="profilesToShowMessage"
        />
        <!-- Search results START-->
        <t-card v-show="!usersLoading && userData.hasFullAccess && profilesToShowCount > 0 && profileInfoLoaded && !containsError"
          class="w-full flex flex-col justify-center items-center lg:w-main-container"
          :class="{'lg:min-h-sidebar':adToShow === null}"
        >
          <ProfileGuest
            :key="'profileSearchModal'"
            @profile-skipped="skipProfile()"
            :profile-skipping="profileSkipping"
            :getting-another-profile="gettingAnotherProfile"
            @add-to-remembered="addToRemembered($event)"
            :profiles-to-show-message="profilesToShowMessage"
            :username="usernameResult"
          />
        </t-card>
        <template v-if="!usersLoading && userData">
          <template v-if="profileInfoLoaded">
            <!-- User-based problems  -->
            <UserProblemNotifications
              :user-data=userData
              :ad-to-show=adToShow
              context="search"
            />
            <!-- Notification not related to logged user problems -->
            <template v-if="userData.hasFullAccess">
              <!-- You can run new search -->
              <MainContainerNotification
                v-if="profilesToShowCount < 1 && !showTimeLimitMessage && !showStartNewSearchWithResetRememberedMessage"
                icon-name="newContactsFilled"
                :section-count=1
                type="success"
              >
                <template v-slot:section1>
                  {{ trans('MESSAGE:SearchYouCanRunNewSearch') }}
                </template>
              </MainContainerNotification>
              <!-- No more profiles, time limit pending -->
              <MainContainerNotification
                v-if="showTimeLimitMessage"
                icon-name="newContactsFilled"
                :section-count=2
                type="info"
              >
                <template v-slot:section1>
                  {{ trans('MESSAGE:SearchNoMoreProfiles1')}}
                </template>
                <template v-slot:section2>
                  {{ trans('MESSAGE:SearchNoMoreProfiles2')}}
                </template>
              </MainContainerNotification>
              <!-- You can run new search but watch remembered list -->
              <MainContainerNotification
                v-if="showStartNewSearchWithResetRememberedMessage"
                icon-name="newContactsFilled" :section-count=2
                type="success"
              >
                <template v-slot:section1>
                  {{ trans('MESSAGE:SearchYouCanRunNewSearch') }}
                </template>
                <template v-slot:section2>
                  {{
                    trans('MESSAGE:SearchInviteRememberedBeforeNextSearch')
                    +' '
                    +transChoice('MESSAGE:SearchSkippedPeopleReappearance',configData.clearRecentSearchHistoryDays)
                  }}
                </template>
              </MainContainerNotification>
            </template>
          </template>
        </template>
        <!-- Advertising content -->
        <template v-if="!isMobile && adToShow !== null && (!userData.hasFullAccess || profilesToShowCount < 1)">
          <t-card v-show="!usersLoading && userData"
            class="w-full flex flex-col justify-center items-center lg:w-main-container lg:min-h-sidebar "
          >
            <PostShow :slug="adToShow" :no-outer-padding=true :no-timestamp=true />
          </t-card>
        </template>
      </div>
      <!-- Search results END -->
    </PageContentWithSidebar>

    <Modal
        hide-footer
        variant="post"
        v-if="isShowBeforeYouStartSearchingModal"
        :show-modal="isShowBeforeYouStartSearchingModal"
        @clicked="openBeforeYouStartSearchingModal(false)"
    >
      <template #content>
        <PostShow slug="before-you-start-searching" />
      </template>
    </Modal>

    <ModalMap
      v-if="isShowMapModal"
      :show-modal="isShowMapModal"
      :all-locations-data="allLocationsData"
      legend-a="LABEL:LocationYour"
      legend-b="LABEL:LocationSelected"
      @clicked="closeMapModal"
    />

    <Modal
      v-if="isShowStartNewSearchModal"
      :show-modal="isShowStartNewSearchModal"
      :header="trans('MODAL_TITLE:Attention')"
      :cancel-button-default = true
      :buttonConfirmText="trans('BUTTON:StartSearch')"
      @clicked="isShowStartNewSearchModal = false"
      @onConfirm="searchProfiles()"
    >
      <template v-slot:content>
        <div class="flex flex-col gap-3 mb-3 lg:ml-3">
          <span v-if="userData.rememberedUsers.length > 0 && usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWillClearRememberedUsersAndSearchResults') }}
          </span>
          <span v-if="userData.rememberedUsers.length > 0 && !usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWillClearRememberedUsers') }}
          </span>
          <span v-if="userData.rememberedUsers.length === 0 && usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWillClearSearchResults') }}
          </span>
          <span v-if="userData.rememberedUsers.length > 0 || usernameResult">
            {{ trans('MODAL_DESCRIPTION:NewSearchWouldYouLikeToContinue') }}
          </span>
        </div>
      </template>
    </Modal>

  </PageContainer>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import Loader from "@/views/Loader";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SearchSidebar from "@/views/content/filters/SearchSidebar";
import UserCard from "@/views/content/components/UserCard";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import UserProblemNotifications from "@/views/content/components/UserProblemNotifications";
import { ref } from '@vue/composition-api'
import {
  goToSpecificRoute,
  getHeightOfScreenWidth,
  getWidth,
  fitMapToShowLocations,
  calculateMarkerStyle
} from "@/common/helpers/utils";
import Modal from "@/utils/modal/Modal.vue";
import ModalMap from "@/views/content/components/ModalMap.vue";
import ProfileGuest from "@/views/content/profile/ProfileGuest.vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Hint from "@/views/content/components/Hint";
import SidebarSectionSeparatorLine from "@/views/content/components/SidebarSectionSeparatorLine"
import PageContentWithSidebar from "@/layouts/components/pages/PageContentWithSidebar"
import Sidebar from "@/layouts/components/pages/Sidebar"
import PostShow from "@/views/content/posts/PostShow.vue";
import SearchLocation from "@/views/content/filters/SearchHistory.vue";
import LocationForm from "@/views/content/profile/components/forms/LocationForm.vue";
import Autocomplete from "@/utils/crud/components/field-types/Autocomplete.vue";
import api from "@/common/services/api";
import Countdown from "@/utils/Countdown.vue";
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";
import MainContainerNotification from "@/views/content/components/MainContainerNotification.vue"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ProfilesCounter from "@/views/content/profile/components/ProfilesCounter";

export default {
  name: "SearchView",
  components: {
    ProfilesCounter,
    Countdown,
    Autocomplete,
    LocationForm,
    SearchLocation,
    ProfileGuest,
    Modal,
    ModalMap,
    Badge,
    BadgeFrame,
    Button,
    BaseIcon,
    Hint,
    Loader,
    PageTitle,
    PageContainer,
    SearchSidebar,
    SidebarSectionSeparatorLine,
    UserCard,
    PageContentWithSidebar,
    Sidebar,
    PostShow,
    ListHeaderSection,
    MainContainerNotification,
    UserProblemNotifications
  },
  data() {
    return {
      goToSpecificRoute: goToSpecificRoute,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      getWidth: getWidth,
      fitMapToShowLocations: fitMapToShowLocations,
      calculateMarkerStyle: calculateMarkerStyle,
      showHistory: true,
      nav: {
        search: true,
        history: false,
        remembered: false,
      },
      prevRoute: null,
      isShowProfileModal: false,
      profileUsername: false,
      isShowBeforeYouStartSearchingModal: false,
      isShowMapModal: false,
      userSearchLocation: null,
      usersLoading: false,
      searchedUserData: null,
      usernameResult: null,
      profilesToShowCount: null,
      profilesToShowMessage: null,
      showTimeLimitMessage: null,
      showStartNewSearchMessage: null,
      showStartNewSearchWithResetRememberedMessage: null,
      showNoMoreProfilesMessage: false,
      profileInfoLoaded: false,
      isShowStartNewSearchModal: false,
      profileSkipping: false,
      profilesSearching: false,
      gettingAnotherProfile: false,
      map:null,
    }
  },
  computed: {
    ...mapGetters('users', ['usersData', 'pagination', 'userFilters', 'favFilters', 'isInitialSearch', 'lastPage', 'backFromNewContacts', '']),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('account', ['userData']),
    ...mapGetters('utils', ['currentSearchNav']),
    ...mapGetters('searchHistories', ['searchHistoryData']),
    ...mapGetters('profile', ['profileRememberedLoaded', 'searchAvailableAt']),
    ...mapGetters('chat', ['messageSent']),
    ...mapGetters('dictionaries', ['locationsData','allLocationsData']),
    ...mapGetters('ads', ['adToShow']),

    isMobile() {
      return checkIsMobileDevice();
    },
    containsError () {
      return this.showNoMoreProfilesMessage
          || this.showStartNewSearchMessage
          || this.showTimeLimitMessage
          || this.showStartNewSearchWithResetRememberedMessage
    }
  },
  watch: {
    langLocale() {
    },
    profileRememberedLoaded(val) {
      if(val) {
        this.getAnotherProfile();
      }
    },
    messageSent(val) {
      if(val) {
				if (this.profilesToShowCount > 1) {
					this.getAnotherProfile();
				}
      }
    },
    'userData.rememberedUsers': {
      immediate: true,
      deep: true,
      handler(val) {
        if(val && val.length === 0) {
          this.showStartNewSearchWithResetRememberedMessage = false;
          if (this.profilesToShowCount === 0) {
            this.showStartNewSearchMessage = true;
          }
        }
      }
    },
    searchHistoryData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.isShowBeforeYouStartSearchingModal = val.length === 0 && !this.userData.modalDisplayedSearch;
        }
      }
    }
  },
  methods: {
    ...mapActions('users', ['fetchUsersData', 'setPage']),
    ...mapActions('searchHistories', ['fetchSearchHistory']),
    ...mapActions('profile', ['addToRememberedRequest']),
    ...mapActions('alert', ['error', 'success']),
    ...mapMutations('users', ['setFilters', 'unsetActiveFilter', 'setLastPage', 'setBackFromNewContacts', 'setUsersData']),
    ...mapMutations('utils', ['setCurrentNav']),
    ...mapMutations('profile', ['setSearchAvailableAt']),
    ...mapMutations('account', ['cleanRememberedNames', 'cleanRememberedUsers']),
    ...mapActions('dictionaries', ['fetchAllLocationsData','fetchLocationsData']),
	  ...mapActions('ads', ['fetchAd']),

    showSidebarNav(val) {
      if(!this.isMobile) {
        return true;
      }
      return val;
    },

    showProfileModal(username) {
      this.isShowProfileModal = true;
      this.profileUsername = username;
    },

    next(val) {
      this.setLastPage(val);
      this.$router.push({name: 'search', query: {page: val}}).catch(err => err)
      this.fetchUsersData({page: val});
    },

    changeNav(section) {
      if (section==='history' && this.nav.history || section==='remembered' && this.nav.remembered) {
        this.setCurrentNav('search');
        this.setNavigationActive('search');
      } else {
        this.setCurrentNav(section);
        this.setNavigationActive(section);
      }
    },

    setNavigationActive(section) {
      Object.keys(this.nav).filter(el => {
        this.nav[el] = section === el;
      })
    },

    openBeforeYouStartSearchingModal(value) {
      if (value === false && !this.userData.modalDisplayedSearch) {
        this.sendModalDisplayedSearchRequest();
      }
      this.isShowBeforeYouStartSearchingModal = value;
    },

    sendModalDisplayedSearchRequest() {
      api.post(`/modal-displayed-search`)
      .then(
        success => {
          this.setUserModalDisplayedSearch()
        },
        error => {
        }
      )
    },

    closeMapModal() {
      this.isShowMapModal = false;
      if (this.map) {
        map.eachLayer(function(m) {
          m.off();
          map.removeLayer(m);
        });
        this.map.remove();
        this.map = null;
      }
    },

    openMapModal(userData, selectedLocation) {

      this.isShowMapModal = true;

      // Upewnij się, że modal jest otwarty zanim zainicjalizujesz mapę
      this.$nextTick(() => {
        // Sprawdź, czy kontener mapy istnieje
        if (document.getElementById('mapContainer')) {

          let map = this.map;
          // Zmienna do przechowywania markerow z mapy do przeskalowywania
          const markers = [];
          let userMarker = null;
          // Warstwa na lokalizacje uzytkownika i wybrana lokalizacje
          let topMarkersLayer = L.layerGroup();
          let mapZoomMin = 6;
          let mapZoomDefault = 8;
          let mapZoomMax = 12;
          // Kolory markerow
          let markerColorDefault = '#888888';
          let markerColorUser = '#1e90ff';
          let markerColorSelected = '#800000';
          // Srodek mapy w srodku Polski
          let centerLatitude = '52.0651';
          let centerLongitude = '19.4794';
          // Srodek mapy w lokalizacji uzytkownika (jesli jest)
          if (userData.location.latitude && userData.location.longitude) {
            centerLatitude = userData.location.latitude;
            centerLongitude = userData.location.longitude;
          }
          map = L.map('mapContainer').setView([centerLatitude, centerLongitude], mapZoomDefault);
          // Dodanie skali
          L.control.scale({
            position: 'bottomright', metric: true, imperial: false, maxWidth: 200
          }).addTo(map);
          // Dodanie warstwy OpenStreetMap
          L.tileLayer(
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            {minZoom: mapZoomMin, maxZoom: mapZoomMax}
          ).addTo(map);
          // Wylaczenie krzyzyka z Popupow
          L.Popup.prototype.options.closeButton = false;

          const canvasRenderer = L.canvas();
          let selectedLocationLatLng = null;
          let userLocationLatLng = L.marker([userData.location.latitude,userData.location.longitude]);


          // --------------------------------------- DLA KAZDEGO PUNKTU NA MAPIE ---------------------------------------
          this.allLocationsData.forEach(point => {
            const marker = L.circleMarker(
              [point.latitude, point.longitude],
              {renderer: canvasRenderer}
            ).addTo(map);

            let distance = Math.round(
                L.latLng([point.latitude, point.longitude]).distanceTo(userLocationLatLng.getLatLng())/1000
            );

            marker.bindPopup(`
              <div class="flex flex-col px-3 py-1.5">
                <div class="w-full flex flex-row gap-1.5">
                  <div class="mont-font font-semibold text-sm">${point.name}</div>
                  <div class="mont-font font-medium text-grayed text-s">${distance>0?'('+distance+' km)':''}</div>
                </div>
                <div class="w-full mont-font font-medium text-grayed text-s">${point.region}</div>
              </div>
            `);

            marker.on('click', function() {
              this.locationSelectedOnMap(point);
            }.bind(this));

            marker.on('mouseover', function() {
              if (userData.location.id !== point.id && selectedLocation !== point.id) {
                calculateMarkerStyle(map, marker, markerColorSelected);
              }
              marker.openPopup();
            });

            marker.on('mouseout', function() {
              if (userData.location.id !== point.id && selectedLocation !== point.id) {
                calculateMarkerStyle(map, marker, markerColorDefault);
              }
              marker.closePopup();
            });

            if (point.id === userData.location.id) {
              userMarker = marker;
            }

            if (point.id === userData.location.id && userData.location.id === selectedLocation) {
              // Wybrana lokalizacja jest lokalizacja uzytkownika (niebieski obrys, czerwony srodek)
              selectedLocationLatLng = null;
              calculateMarkerStyle(map, marker, markerColorUser, markerColorSelected);
            } else {
              // Wybrana lokalizacja nie jest lokalizacja uzytkownika
              if (point.id === selectedLocation) {
                // Wybrana lokalizacja
                selectedLocationLatLng = L.marker([point.latitude, point.longitude]);
                calculateMarkerStyle(map, marker, markerColorSelected);
              } else if (point.id === userData.location.id) {
                // Lokalizacja uzytkownika
                calculateMarkerStyle(map, marker, markerColorUser);
              } else {
                // Kazda lokalizacja
                calculateMarkerStyle(map, marker, markerColorDefault);
              }
            }

            if (userData.location.id === point.id || selectedLocation === point.id) {
              // Dodanie lokalizacji uzytkownika i wybrnaej lokalizacji do dodatkowej warstwy
              marker.addTo(topMarkersLayer);
            } else {
              marker.addTo(map);
            }
            // Dodanie markera do listy markerow
            markers.push(marker);
          });
          //----------------------------------------------- KONIEC PETLI -----------------------------------------------

          // Dodanie dodatkowej warstwy do mapy
          topMarkersLayer.addTo(map);
          userMarker.openPopup();
          // Przeskalowanie mapy, jesli jest wybrana lokalizacja
          fitMapToShowLocations(map, userLocationLatLng, selectedLocationLatLng);

          // Obsluga zoomowania mapy
          map.on('zoomend', function() {
            markers.forEach(m => {
              calculateMarkerStyle(map, m, m.options.color, m.options.fillColor)
            });
          });

        }
      });
    },

    acceptLocation(value) {
      this.userSearchLocation = value.id;
    },
    useMyLocation(userData) {
      this.locationSelected(userData.location)
    },
    locationSelected(location) {
      this.userSearchLocation = location.id;
      this.$refs.autocomplete.search = location.descRegion;
      this.$refs.autocomplete.manageClick(location);
    },
    locationSelectedOnMap(location) {
      this.locationSelected(location)
      this.closeMapModal();
    },
    showStartNewSearchAlertModal(value) {
      this.isShowStartNewSearchModal = value;
    },
    searchProfiles() {
      this.$refs.autocomplete.search = '';
      this.showStartNewSearchAlertModal(false);
      this.profilesSearching = true;
      let data = {
        location_id: this.userSearchLocation,
        user_id: this.userData.id,
      };
      api.post(`/search-profiles`, data)
        .then(
          success => {
            this.fetchSearchHistory()
            this.usernameResult = success.data.username;
            this.getSearchProfilesInfo();
            this.cleanRememberedNames();
            this.cleanRememberedUsers();
          },
          error => {
            this.error(error);
          }
        )
        .finally(() => {
          this.userSearchLocation = null;
          this.profilesSearching = false;
        }
        );
    },


    getSearchUser() {
      this.usersLoading = true;

      api.get(`/users`)
          .then(
              success => {
                if (success.data.username) {
                  this.usernameResult = success.data.username;
                  this.getSearchProfilesInfo();
                } else {
                  this.usernameResult = null;
                }
              },
              error => {
              }
          ).finally(() => {
        this.usersLoading = false;
      });
    },

    getSearchProfilesInfo() {
      this.profileInfoLoaded = false;

      api.get(`/search-profiles-info`)
          .then(
              success => {
                this.profileInfoLoaded = true;
                if(success.data.searchAvailableAt !== null) {
                  this.setSearchAvailableAt(new Date(success.data.searchAvailableAt));
                } else {
                  this.setSearchAvailableAt(null);
                }
                this.profilesToShowCount = success.data.profilesToShowCount;
                this.profilesToShowMessage = success.data.profilesToShowMessage;
                this.showTimeLimitMessage = success.data.showTimeLimitMessage;
                this.showStartNewSearchMessage = success.data.showStartNewSearchMessage;
                this.showStartNewSearchWithResetRememberedMessage = success.data.showStartNewSearchWithResetRememberedMessage;
                this.showNoMoreProfilesMessage = success.data.showNoMoreProfilesMessage;
              },
              error => {
              }
          ).finally(() => {
        this.profileInfoLoaded = true;
      });
    },

    skipProfile() {
      this.profileSkipping = true;
      api.post(`/skip-profile`)
          .then(
              success => {
                this.getAnotherProfile();
              },
              error => {
              }
          ).finally(() => {
        this.profileSkipping = false;
      });
    },

    getAnotherProfile() {
      this.gettingAnotherProfile = true;
      api.post(`/get-another-profile`)
          .then(
              success => {
                this.usernameResult = success.data.username;

                this.getSearchProfilesInfo();
              },
              error => {
              }
          ).finally(() => {
        this.gettingAnotherProfile = false;
      });
    },

    addToRemembered(event) {
      this.addToRememberedRequest({rememberedUsername: event.username});
      this.getAnotherProfile();
    },

    clearTimer() {
      this.getSearchProfilesInfo();
      this.userSearchLocation=null;
    },

    async loadAllLocationsData() {
      try {
        // Czekaj na zakończenie pobierania danych
        await this.fetchAllLocationsData();
        console.log('Pobrane dane lokalizacji.');
      } catch (error) {
        console.error('Błąd podczas pobierania danych lokalizacji:', error);
      }
    }

  },

  setup() {
    const currentPage = ref(1)

    return { currentPage }
  },

  mounted() {
    if(this.currentSearchNav) {
      this.setNavigationActive(this.currentSearchNav);
    }
    this.getSearchProfilesInfo();
    this.getSearchUser();
    this.loadAllLocationsData();
		this.fetchAd();
  },
  beforeMount() {
  },
  beforeDestroy() {
    this.setUsersData([]);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  provide() {
    return {
      searchAllowed: this.searchAllowed
    };
  }
}
</script>
<style lang="scss">
.leaflet-popup-content-wrapper {
  padding: 0;
  margin:0;
  border-radius: 0.25rem;
}
.leaflet-popup-content{
  padding:0;
  margin: 0;
}
</style>