<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Users') }}
    </PageTitle>
    <Loader v-if="usersDataLoading" class="h-050screen"/>
    <t-card v-else class="w-full">
      <div class="w-full" v-if="usersData">
        <div class="w-1/3 mb-3">
          <SelectField
            :options="userFiltersData"
            v-model="userFilter"
          />
        </div>
        <DataTable
          :data="usersData"
          :headers="tableHeaders"
          custom-actions
          color-row-attr="shouldColorRow"
        >
          <template #actions="{item}">
            <div class="flex flex-col">
              <Button
                  v-if="item.bannedAt === null && item.removedAt === null"
                  variant="link"
                  class="text-badgeError"
                  :text="trans('BUTTON:BanUser')"
                  @clicked="showBanReasonModal(item)"
              />
              <Button
                  v-if="item.bannedAt"
                  variant="link"
                  class="text-badgeError"
                  :text="trans('BUTTON:UnbanUser')"
                  @clicked="unblockUser(item)"
              />
              <Button
                  v-if="!item.removedAt"
                  class="text-badgeError"
                  variant="link"
                  :text="trans('BUTTON:Delete')"
                  @clicked="showRemoveModal(item)"
              />
            </div>
          </template>
          <template #name="{item}">
	          <div v-if="item.isDuplicate">
		          <Badge tagName="span" variant="badgeError">
			          {{ trans('LABEL:DuplicateProfile') }}
		          </Badge>
	          </div>
            <div
              class="cursor-pointer hover:opacity-50"
              @click="showProfileModal(item.username)"
            >
              {{  item.removedAt? trans(item.name) : item.name.length<20?item.name: item.name.substring(0,17)+'...'}}
            </div>
            {{ item.username }}
          </template>
          <template #paymentStatus="{item}">
            <template v-if="item.paymentStatus">
              {{ item.paymentStatus }}<span v-if="item.subscriptionDaysLeft>0"> ({{item.subscriptionDaysLeft}})</span><br/>
              <Button
                v-if="item.payments.length > 0"
                variant="link"
                :text="trans('BUTTON:PaymentsList')"
                @clicked="showPayments(item)"
              />
            </template>
            <template v-else>
              &ndash;
            </template>
          </template>
          <template #bannedAt="{item}">
            <template v-if="item.bannedAt">
              <VTooltip>
                <span class="flex flex-row items-center gap-2 hover:opacity-50 cursor-pointer">
                  <base-icon
                    class="w-3 h-3"
                    name="ban"
                    color="fill-badgeNeutral"
                  />
                  {{ item.bannedAt }}
                </span>
                <template #popper>
	                <span v-html="item.banReason"></span>
                </template>
              </VTooltip>
            </template>
          </template>

          <template #deletedStatus="{item}">
            <div class="flex flex-col gap-1">
              <div v-if="item.deletedAt" class="flex flex-row items-center gap-2">
                <base-icon
                  class="w-3 h-3"
                  name="profileXFilled"
                  color="fill-badgeNeutral"
                />
                {{ item.deletedAt }}
              </div>
              <div v-if="item.removedAt" class="flex flex-row items-center gap-2">
                <base-icon
                    class="w-3 h-3"
                    name="x"
                    color="fill-badgeNeutral"
                />
                {{ item.removedAt }}
              </div>
            </div>
          </template>
        </DataTable>
        <t-pagination
          class="mt-3"
          variant="posts"
          :total-items="pagination.total"
          :per-page="pagination.perPage"
          :limit="5"
          v-model="currentPage"
          @change="next"
        />
      </div>
    </t-card>

    <!-- User profile -->
    <Modal
      hide-footer
      v-if="isShowProfileModal"
      :show-modal="isShowProfileModal"
      @clicked="isShowProfileModal = false"
      variant="profileAdmin"
    >
      <template #content>
        <ProfileGuest :on-modal=true :username="profileUsername" key="profileUsersListModal"/>
      </template>
    </Modal>

    <!-- Payment list -->
    <Modal
      v-if="showPaymentsUserModalId"
      :show-modal="true"
      @clicked="showPaymentsUserModalId = null"
      :header="trans('MODAL_TITLE:PaymentsList')+' - '+profileUsername"
      variant="tableAdmin"
    >
      <template v-slot:content>
        <DataTable :data="getPaymentsList.payments" :headers="tablePaymentsHeaders"/>
      </template>
      <template v-slot:footer>
        <Button
          variant="buttonRed"
          :text="trans('BUTTON:Close')"
          @clicked="hidePayments()"
        />
      </template>
    </Modal>

    <!-- Block user confirm-->
    <Modal
      v-if="isShowBanReasonModal"
      :show-modal="true"
      @clicked="showBanReasonModal"
      :header="trans('MODAL_TITLE:BlockUser')"
      hide-footer
      variant="profileAdmin"
    >
      <template v-slot:content>
        <BlockReasonForm :user="userToBlock"/>
      </template>
    </Modal>

    <!-- Remove user confirm-->
    <Modal
      v-if="isShowRemoveModal"
      :show-modal="true"
      @clicked="showRemoveModal"
      :header="trans('MODAL_TITLE:RemoveUser')"
      @onConfirm="removeUserAction"
      :loading="userRemoving"
      :buttonConfirmText="trans('BUTTON:Delete')"
      variant="profileAdmin"
    >
      <template v-slot:content>
        {{ trans('MODAL_DESCRIPTION:RemoveUser') }}
      </template>
    </Modal>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import BaseIcon from "@/utils/icons/BaseIcon";
import BlockReasonForm from "@/views/content/components/BlockReasonForm";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import ProfileGuest from "@/views/content/profile/ProfileGuest";
import SelectField from "@/utils/crud/components/field-types/Select.vue";
import {mapActions, mapGetters} from "vuex";
import Badge from "@/utils/crud/components/Badge.vue";

export default {
  name: "UsersList",
  components: {
	  Badge,
    AdminPageFrame,
    BaseIcon,
    BlockReasonForm,
    Button,
    DataTable,
    Loader,
    Modal,
    PageTitle,
    Pagination,
    ProfileGuest,
    SelectField,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:Actions',
          value: 'actions',
        },
        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Name',
          value: 'name',
        },
        {
          name: 'LABEL:Email',
          value: 'email',
        },
        {
          name: 'LABEL:Verification',
          value: 'verificationStatus',
        },
        {
          name: 'LABEL:Subscription',
          value: 'paymentStatus',
        },
        {
          name: 'LABEL:BanHeader',
          value: 'bannedAt',
        },
        {
          name: 'LABEL:DeletionHeader',
          value: 'deletedStatus',
        },
      ],
      tablePaymentsHeaders: [
        {
          name: 'LABEL:Status',
          value: 'statusDesc',
        },
        {
          name: 'LABEL:CreatedAt',
          value: 'createdAt',
        },
        {
          name: 'LABEL:Price',
          value: 'totalAmount',
        },
        {
          name: 'LABEL:Days',
          value: 'days',
        },
        {
          name: 'LABEL:DiscountDays',
          value: 'discountDays',
        },
        {
          name: 'LABEL:DiscountName',
          value: 'discountName',
        },
        {
          name: 'LABEL:Partner',
          value: 'partnerName',
        },
      ],
      currentPage: 1,
      showPaymentsUserModalId: null,
      isShowBanReasonModal: false,
      isShowRemoveModal: false,
      isShowProfileModal: false,
      userToBlock: {},
      userToRemove: {},
      userFilter: 1,
      profileUsername:'',
    }
  },
  watch: {
    userBanned(val) {
      if(val) {
        this.showBanReasonModal();
      }
    },
    userRemoved(val) {
      if(val) {
        this.showRemoveModal();
      }
    },
    userFilter(val) {
      this.currentPage = 1;
      this.fetchUsersRequest({page: this.currentPage, filter: val});
    }
  },
  computed: {
    ...mapGetters('adminUsers', ['usersData', 'usersDataLoading', 'pagination', 'userBanned', 'userRemoving', 'userRemoved']),
    ...mapGetters('dictionaries', ['userFiltersData']),
    getPaymentsList() {
      return this.usersData.find(el => el.id === this.showPaymentsUserModalId);
    }
  },
  methods: {
    ...mapActions('adminUsers', ['fetchUsersRequest', 'banUserRequest', 'removeUserRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchUsersRequest({
        page: val,
        filter: this.userFilter
      });
    },

    showProfileModal(username) {
      this.isShowProfileModal = true;
      this.profileUsername = username;
    },

    showPayments(item) {
      this.showPaymentsUserModalId = item.id;
      this.profileUsername = item.name + ' (' +  item.username + ')';
    },
    hidePayments() {
      this.showPaymentsUserModalId = null;
    },

    showBanReasonModal(item = null) {
      if(item) {
        this.isShowBanReasonModal = true;
        this.userToBlock = item;
      } else {
        this.isShowBanReasonModal = false;
        this.userToBlock = {};
      }
    },
    showRemoveModal(item = null) {
      if(item) {
        this.isShowRemoveModal = true;
        this.userToRemove = item;
      } else {
        this.isShowRemoveModal = false;
        this.userToRemove = {};
      }
    },
    removeUserAction() {
      this.removeUserRequest({userId: this.userToRemove.id})
    },
	  unblockUser(item) {
			this.banUserRequest({userId: item.id, unban: true})
	  }

  },
  mounted() {
    this.fetchUsersRequest({page: this.currentPage, filter: this.userFilter});
  }
}
</script>

