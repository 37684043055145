const staticSites = [
    {
        path: '/',
        name: 'homepage',
        component: () => import('@/views/content/static/Homepage.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
            allowBanned: true,
            transName: 'Homepage'
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/content/static/About.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
            allowBanned: true,
        },
    },
]

export default staticSites
