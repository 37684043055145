<template>
    <PageContainer :center-vertically=true>
      <PageTitle :register-screen="true">
        <div class="flex flex-row items-center gap-3">
          <div>
            {{ trans('FORM_TITLE:Register') }}
          </div>
          <VTooltip>
            <span class="flex flex-row items-center">
              <Button
                variant="link"
                @clicked="openBeforeYouRegisterModal(true)"
              >
                <base-icon  name="info" width="20" height="20">
                </base-icon>
              </Button>
            </span>
            <template #popper>
              {{ trans('LABEL:BeforeYouRegister') }}
            </template>
          </VTooltip>
        </div>
      </PageTitle>

	    <t-card variant="auth">
        <ValidationObserver ref="registerForm" v-slot="{ valid }">
          <form
            @submit.prevent="handleFormSubmit"
            @keydown.enter="handleNextStep($event, currentStep)"
          >
            <!--		STEP 0    -->
            <RegisterCard v-show="currentStep === 0">
              <template #picture>
                <img class="w-full" :src="themeConfig.staticPhotos.registerStep0" />
              </template>
              <template #content>
                <div class="flex flex-col gap-3">
                  <div class="text-base">
                    {{ trans('TEXT:RegistrationIntro1') }}
                  </div>
                  <div class="text-right">
                    {{ trans('TEXT:RegistrationIntro2') }}
                 </div>
                </div>
              </template>
              <template #bottom-left>
                {{ trans('TEXT:AlreadyRegistered') }}&nbsp;
                <Button
                  :to="{name: 'auth-login'}"
                  variant="underline"
                  :text="trans('BUTTON:LogIn!')"
                />
              </template>
              <template #bottom-right>
                <Button
                  variant="buttonRed"
                  @clicked="nextStep"
                  :text="trans('BUTTON:Next')"
                />
              </template>
            </RegisterCard>
            <!--		STEP 1    -->
              <ValidationObserver ref="step1" v-slot="{ invalid }">
                <RegisterCard v-show="currentStep === 1">
                  <template #picture>
                    <img class="w-full" :src="themeConfig.staticPhotos.registerStep1" />
                  </template>
                  <template #title>
                    {{ trans('TEXT:RegistrationStep1of4') }}
                  </template>
                  <template #content>
                    <div class="flex flex-col gap-6">
                      <div class="text-base">
                        {{ trans('TEXT:RegistrationStep1of4Description') }}
                      </div>
                      <div class="flex flex-col gap-3">
                        <FieldWrapper>
                          <TextField
                            field="email"
                            autocomplete
                            :placeholder="trans('LABEL:EmailLogin')"
                            rules="email|required|max:255"
                            :custom-error="customEmailError"
                            @input="checkEmail"
                            v-model="user.email"
                          />
                        </FieldWrapper>
                        <FieldWrapper>
                          <TextField
                            field="name"
                            name="name"
                            rules="required|min:8|max:50"
                            v-model="user.name"
                            :placeholder="trans('LABEL:DisplayedName')"
                          />
                        </FieldWrapper>
                      </div>
                    </div>
                  </template>
                  <template #bottom-left>
                    <Button
                      variant="underline"
                      @clicked="previousStep"
                      :text="trans('BUTTON:PreviousStep')"
                    />
                  </template>
                  <template #bottom-right>
                    <Button
                      @clicked="nextStep"
                      :disabled="!isEmailValid || invalid"
                      :text="trans('BUTTON:Next')"
                    />
                  </template>
                </RegisterCard>
              </ValidationObserver>
            <!--		STEP 2    -->
              <ValidationObserver ref="step2" v-slot="{ invalid }">
                <RegisterCard v-show="currentStep === 2">
                  <template #picture>
                    <img class="w-full" :src="themeConfig.staticPhotos.registerStep2" />
                  </template>
                  <template #title>
                    {{ trans('TEXT:RegistrationStep2of4') }}
                  </template>
                  <template #content>
                    <div class="flex flex-col gap-6">
                      <div class="text-base">
                        {{ trans('TEXT:RegistrationStep2of4Description') }}
                      </div>
                      <div class="flex flex-col gap-3">
                        <FieldWrapper>
                          <TextField
                            isPassword
                            field="password"
                            rules="required|min:8|max:50"
                            v-model="user.password"
                            :placeholder="trans('LABEL:PasswordPlaceholder')"
                          />
                        </FieldWrapper>
                        <FieldWrapper noMargin>
                          <TextField
                            isPassword
                            field="password_confirmation"
                            rules="required|confirmed:password"
                            v-model="user.password_confirmation"
                            :placeholder="trans('LABEL:PasswordConfirm')"
                          />
                        </FieldWrapper>
                      </div>
                    </div>
                  </template>
                  <template #bottom-left>
                    <Button
                      variant="underline"
                      @clicked="previousStep"
                      :text="trans('BUTTON:PreviousStep')"
                    />
                  </template>
                  <template #bottom-right>
                    <Button
                      @clicked="nextStep"
                      :disabled="invalid"
                      :text="trans('BUTTON:Next')"
                    />
                  </template>
                </RegisterCard>
              </ValidationObserver>
            <!--		STEP 3    -->
              <ValidationObserver ref="step3" v-slot="{ invalid }">
                <RegisterCard v-show="currentStep === 3">
                  <template #picture>
                    <img class="w-full" :src="themeConfig.staticPhotos.registerStep3" />
                  </template>
                  <template #title>
                    {{ trans('TEXT:RegistrationStep3of4') }}
                  </template>
                  <template #content>
                    <div class="flex flex-col gap-6">
                      <div class="text-base">
                        {{ trans('TEXT:RegistrationStep3of4Description') }}
                      </div>
                      <div class="w-full flex flex-col gap-3">
                        <FieldWrapper class="w-fulll">
                          <SelectField
                            v-model="user.year_of_birth"
                            rules="required"
                            field="year_of_birth"
                            :placeholder="trans('LABEL:YearOfBirth')"
                            :options="yearsData"
                          />
                        </FieldWrapper>
                        <FieldWrapper class="w-full">
                          <SelectField
                            v-model="user.gender_formal_id"
                            rules="required"
                            field="gender_formal_id"
                            :placeholder="trans('LABEL:GenderFormal')"
                            :options="genderFormalData"
                          />
                        </FieldWrapper>
                        <CheckboxField
                          field="gender_different_than_formal"
                          v-model="user.gender_different_than_formal"
                          :disabled="user.gender_formal_id === ''"
                          :label="trans('LABEL:GenderDifferentThanFormal')"
                        >
                        </CheckboxField>
                        <FieldWrapper class="w-full" v-if="user.gender_different_than_formal">
                          <SelectField
                            v-model="user.gender_declared_id"
                            rules="required"
                            field="gender_declared_id"
                            :placeholder="trans('LABEL:GenderDeclared')"
                            :options="gendersDeclaredFiltered"
                          />
                        </FieldWrapper>
                      </div>
                    </div>
                  </template>
                  <template #bottom-left>
                    <Button
                      variant="underline"
                      @clicked="previousStep"
                      :text="trans('BUTTON:PreviousStep')"
                    />
                  </template>
                  <template #bottom-right>
                    <Button
                      @clicked="nextStep"
                      :disabled="invalid"
                      :text="trans('BUTTON:Next')"
                    />
                  </template>
                </RegisterCard>
              </ValidationObserver>
            <!--		STEP 4    -->
              <ValidationObserver ref="step4" v-slot="{ invalid }">
                <RegisterCard v-show="currentStep === 4">
                  <template #picture>
                    <img class="w-full" :src="themeConfig.staticPhotos.registerStep4" />
                  </template>
                  <template #title>
                    {{ trans('TEXT:RegistrationStep4of4') }}
                  </template>
                  <template #content>
                    <div class="flex flex-col gap-6">
                      <div class="text-base">
                        {{ trans('TEXT:RegistrationStep4of4Description') }}
                      </div>
                      <div class="w-full flex flex-col gap-3">
                        <TextField
                          field="partner_code"
                          rules="required"
                          v-model="user.partner_code"
                          @input="checkPartnerCode"
                          :custom-error="customErrorPartnerCode"
                          :placeholder="trans('LABEL:PartnerCode')"
                        />
                      </div>
	                    <div class="flex flex-row items-center justify-between">
                        <Button
                          class="text-sm mt-3"
                          variant="link"
                          :blank="true"
                          :text="trans('BUTTON:GetRandomPartnerCode')"
                          @clicked="getRandomPartnerCode"
                        />
                        <Button
                          class="text-sm mt-3"
                          :blank="true"
                          :text="trans('LABEL:ShowPartnersList')"
                          @clicked="isShowPartnersModal = true"
                        />
	                    </div>
                    </div>
                  </template>
                  <template #bottom-left>
                    <Button
                      variant="underline"
                      @clicked="previousStep"
                      :text="trans('BUTTON:PreviousStep')"
                    />
                  </template>
                  <template #bottom-right>
                    <Button
                      :disabled="!isPartnerCodeValid || invalid"
                      @clicked="nextStep"
                      :text="trans('BUTTON:Next')"
                    />
                  </template>
                </RegisterCard>
              </ValidationObserver>
            <!--		STEP 5    -->
	          <ValidationObserver ref="step5" v-slot="{ invalid }">
	            <RegisterCard v-show="currentStep === 5">
	              <template #picture>
	                <img class="w-full" :src="themeConfig.staticPhotos.registerStepSummary" />
	              </template>

	              <template #title>
	                {{ trans('TEXT:RegistrationSummary') }}
	              </template>
	              <template #content>
	                  <div class="w-full flex flex-col">
                      <div class="flex flex-row w-full justify-between">
                        <div class="text-sm">
                          {{ trans("LABEL:Name") }}:
                        </div>
                        <div class="w-2/3 text-right">
                          {{ user.name }}
                        </div>
                      </div>
	                    <div class="flex flex-row w-full justify-between mt-3">
	                      <div class="text-sm">
                          {{ trans("LABEL:Email") }}:
	                      </div>
	                      <div class="w-2/3 text-right">
	                        {{ user.email }}
	                      </div>
	                    </div>
                      <div class="flex flex-row w-full justify-between">
                        <div class="text-sm">
                          {{ trans("LABEL:Password") }}:
                        </div>
	                      <div class="w-2/3 text-right">
	                        ********
	                      </div>
	                    </div>

                      <div class="flex flex-row w-full justify-between mt-3">
                        <div class="text-sm">
                          {{ trans("LABEL:YearOfBirth") }}:
                        </div>
	                      <div class="w-2/3 text-right">
	                        {{ user.year_of_birth }}
	                      </div>
	                    </div>
                      <div class="flex flex-row w-full justify-between">
                        <div class="text-sm">
                          {{ trans("LABEL:GenderFormal") }}:
                        </div>
	                      <div class="w-2/3 text-right">
	                        {{ getGenderFormal(user.gender_formal_id) }}
	                      </div>
	                    </div>
                      <div class="flex flex-row w-full justify-between" v-if="user.gender_different_than_formal">
                        <div class="text-sm">
                          {{ trans("LABEL:GenderDeclared") }}:
                        </div>
	                      <div class="w-2/3 text-right">
	                        {{ getGenderDeclared(user.gender_declared_id) }}
	                      </div>
	                    </div>
                      <div class="flex flex-row w-full justify-between mt-3">
                        <div class="text-sm">
                          {{ trans("LABEL:PartnerCode") }}:
                        </div>
	                      <div class="w-2/3 text-right">
	                        {{ user.partner_code }}
	                      </div>
	                    </div>
	                  </div>
	                  <div class="flex items-center justify-start gap-2 mt-3">
	                    <CheckboxField
	                      field="regulations_accepted_at"
	                      v-model="user.regulations_accepted_at"
	                      :label="trans('LABEL:RulesConsent1')+' '+trans('LABEL:RulesConsent2')"
	                      :label-as-click="true"
	                      @labelClick="openRulesModal(true)"
	                    />
	                    <VTooltip>
	                      <span class="flex flex-row items-center">
	                        <Button
	                          variant="link"
	                          @clicked="openRulesModal(true)"
	                        >
	                          <base-icon  name="info" width="14" height="14">
	                          </base-icon>
	                        </Button>
	                      </span>
	                      <template #popper>
	                        {{ trans('PAGE_TITLE:Rules') }}
	                      </template>
	                    </VTooltip>
	                  </div>
	                  <div class="flex items-center justify-start mt-3">
	                    <recaptcha ref="recaptcha" @verify="submitCaptcha"></recaptcha>
	                  </div>
	              </template>
	              <template #bottom-left>
	                <Button
	                  variant="underline"
	                  @clicked="previousStep"
	                  :text="trans('BUTTON:FixYourData')"
	                />
	              </template>
	              <template #bottom-right>
	                <Button
	                  type="submit"
	                  :loading="registerLoading"
	                  :text="trans('BUTTON:RegisterMe')"
	                />
	              </template>
	            </RegisterCard>
	          </ValidationObserver>
            <RegisterCard v-show="currentStep === 6">
              <template #picture>
                <img class="w-full" :src="themeConfig.staticPhotos.registerStepComplete" />
              </template>
              <template #title>
                {{ trans('TEXT:RegistrationCongratulations') }}
              </template>
              <template #content>
                {{ trans('TEXT:RegistrationCongratulationsDescription') }}
                <div class="flex flex-row justify-end mt-3 gap-1 text-sm">
                  {{ trans('TEXT:LinkDidNotArrive') }}
                  <Button
                    variant="link"
                    @clicked="resendVerificationAction"
                    :disabled="resendLinkDisable"
                    :text="trans('BUTTON:SendVerificationEmailAgain')"
                  />
                </div>
              </template>
            </RegisterCard>
            <RegisterCard v-show="currentStep === 7">
              <template #picture>
                <img class="w-full" :src="themeConfig.staticPhotos.registerStepVerified" />
              </template>
              <template #title>
                {{ trans('TEXT:RegistrationWelcome') }}
              </template>
              <template #content>
                <div class="flex flex-col gap-6">
                  <div class="text-base">
                    {{ trans('TEXT:RegistrationWelcomeDescription') }}
                  </div>
                </div>
              </template>
              <template #bottom-right>
                <Button
                  @clicked="goToLogin"
                  :text="trans('BUTTON:LogIn!')"
                />
              </template>
            </RegisterCard>
          </form>
        </ValidationObserver>
	    </t-card>

      <Modal
        hide-footer
        variant="post"
        v-if="isShowRulesModal"
        :show-modal="isShowRulesModal"
        @clicked="openRulesModal(false)"
      >
        <template #content>
          <PostShow slug="rules" />
        </template>
      </Modal>

	    <Modal
        hide-footer
        variant="post"
        v-if="isShowPartnersModal"
        :show-modal="isShowPartnersModal"
        @clicked="isShowPartnersModal = false"
	    >
		    <template #content>
			    <Partners :is-modal="true"/>
		    </template>
	    </Modal>

      <Modal
        hide-footer
        variant="post"
        v-if="isShowBeforeYouRegisterModal"
        :show-modal="isShowBeforeYouRegisterModal"
        @clicked="openBeforeYouRegisterModal(false)"
      >
        <template #content>
          <PostShow slug="before-you-register" />
        </template>
      </Modal>

    </PageContainer>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import SelectField from "@/utils/crud/components/field-types/Select";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox.vue";
import Modal from "@/utils/modal/Modal.vue";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Recaptcha from "@/utils/Recaptcha.vue";
import api from "@/common/services/api";
import PostShow from "@/views/content/posts/PostShow.vue";
import RegisterCard from "@/views/content/register/RegisterCard.vue";
import themeConfig from "@/themeConfig";
import {isEmpty} from "lodash/lang";
import router from "@/router";
import Partners from "@/views/content/partners/Partners.vue";
import debounce from "lodash/debounce";
import BaseIcon from "@/utils/icons/BaseIcon.vue";

const generateYearsBetween = function (min, max) {
  const endDate = new Date();
  const startDate = new Date();
  endDate.setFullYear(endDate.getFullYear() - min)
  startDate.setFullYear(endDate.getFullYear() - max)
  let years = [];
  let start = startDate.getFullYear()
  let end = endDate.getFullYear()
  for (let i = end; i >= start; i--) {
    years.push(end);
    end--;
  }
  return years;
}

export default {
  components: {
    BaseIcon,
	  Partners,
	  RegisterCard,
	  Modal,
	  Recaptcha,
    Button,
    CheckboxField,
    FieldWrapper,
    PageContainer,
    PageTitle,
    PostShow,
    SelectField,
    TextField,
  },

  data () {
    return {
      isMobile: checkIsMobileDevice(),
      user: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
	      gender_declared_id: null,
	      gender_formal_id: null,
	      gender_different_than_formal: false,
        year_of_birth: '',
        regulations_accepted_at: false,
	      recaptcha: '',
        partner_code: '',
      },
      yearsData: null,
      customEmailError: null,
      customErrorPartnerCode: null,
	    isShowRulesModal: false,
      isShowBeforeYouRegisterModal: true,
	    genderFormalData: {},
	    currentStep: 0,
	    isShowPartnersModal: false,
	    isEmailValid: false,
	    isPartnerCodeValid: false,
	    userEmail: null,
    }
  },
  computed: {
	  themeConfig() {
		  return themeConfig
	  },
    ...mapState('account', ['status', 'registered']),
    ...mapGetters('dictionaries', ['genderData', 'postCategoriesData', 'genderDeclaredData']),
    ...mapGetters('lang', ['langLocale', 'configData']),
	  ...mapGetters('posts', ['postData']),
	  ...mapGetters('account', ['resendVerificationLoading', 'resendLinkDisable', 'userData', 'errors', 'registerLoading']),

		gendersDeclaredFiltered() {
			return this.genderDeclaredData.filter(el => el.id !== Number(this.user.gender_formal_id))
		}
  },
  watch: {
    langLocale() {
      this.fetchGenderDeclaredData();
    },
    errors(val) {
      if(val.hasOwnProperty('email')) {
        this.customEmailError = 'EMAIL';
      }
      if(val.hasOwnProperty('partner_code') && val['partner_code'][0] === this.trans('MESSAGE:PartnerCodeRequired')) {
        this.customErrorPartnerCode = 'PARTNER_CODE';
      } else {
        this.customErrorPartnerCode = null;
      }
	    this.$set(this.user, 'recaptcha', null)
	    this.$refs.recaptcha.reset();
      this.$refs.registerForm.setErrors(val);
    },
	  registered(val) {
			if(val) {
				this.currentStep = 6;
				this.userEmail = this.user.email;
				this.user = {
					name: '',
					email: '',
					password: '',
					password_confirmation: '',
					gender_declared_id: null,
					gender_formal_id: null,
					gender_different_than_formal: false,
					year_of_birth: '',
					regulations_accepted_at: false,
					recaptcha: '',
					partner_code: '',
				}
			}
	  },
  },
  methods: {
    ...mapActions('account', ['register', 'resendVerificationEmail']),
    ...mapActions('dictionaries', ['fetchGenderDeclaredData']),

	  checkEmail: debounce(function () {
		  if (!isEmpty(this.user.email)) {
			  this.checkEmailRequest();
		  } else {
			  this.customEmailError = null;
		  }
	  }, 700),

	  checkPartnerCode: debounce(function () {
		  if (!isEmpty(this.user.partner_code)) {
			  this.checkPartnerCodeRequest();
		  } else {
			  this.customErrorPartnerCode = null;
		  }
	  }, 300),

	  checkEmailRequest() {
		  api.post(`/validate/email`, {email: this.user.email})
				  .then(
						  success => {
								this.isEmailValid = success.data.check;

							  if (!this.isEmailValid) {
								  this.customEmailError = 'EMAIL_EXISTS'
							  } else {
								  this.customEmailError = null;
							  }
						  },
						  error => {
						  }
				  ).finally(() => {
		  });
	  },

	  checkPartnerCodeRequest() {
		  api.post(`/validate/partner-code`, {partner_code: this.user.partner_code})
				  .then(
						  success => {
							  this.isPartnerCodeValid = success.data.check;

								if (!this.isPartnerCodeValid) {
									this.customErrorPartnerCode = 'PARTNER_CODE_NOT_EXISTS'
								} else {
									this.customErrorPartnerCode = null;
								}
						  },
						  error => {
						  }
				  ).finally(() => {
		  });
	  },

    handleFormSubmit(e) {
			e.preventDefault()
      const isFormValid = this.$refs.registerForm.validate()

      if (!isFormValid) {
				return;
      }

	    this.user.lang = this.langLocale;

			if (!!this.user.gender_different_than_formal === false) {
				this.user.gender_declared_id = this.user.gender_formal_id;
			}

      this.register(this.user);
    },

	  fetchGendersOnPaper() {
			api.setLocale()
		  api.get(`/dictionaries/genders-formal`)
				  .then(
						  success => {
							  this.genderFormalData = success.data;
						  },
						  error => {
						  }
				  ).finally(() => {
		  });
	  },

	  openRulesModal(value) {
			this.isShowRulesModal = value;
	  },

    openBeforeYouRegisterModal(value) {
      this.isShowBeforeYouRegisterModal = value;
    },

    submitCaptcha(response) {
			this.user.recaptcha = response;
	  },

	  nextStep() {
			this.currentStep++;
	  },

	  previousStep() {
			this.currentStep--;
	  },

	  getGenderDeclared(id) {
			if (isEmpty(id)) {
				return
			}

		  return this.genderDeclaredData.find(el => el.id === Number(id)).name;
	  },

		getGenderFormal(id) {
			if (isEmpty(id)) {
				return
			}

			return this.genderFormalData.find(el => el.id === Number(id)).name;
		},
	  resendVerificationAction() {
		  this.resendVerificationEmail({email: this.userEmail});
	  },
	  goToLogin() {
		  router.push({ name: 'auth-login' })
	  },
	  handleNextStep(e, currentStep) {
			let step = 'step' + currentStep;
			if (this.$refs[step] !== undefined) {
				if (currentStep === 1) {
					this.$refs[step].validate().then(valid => {
						if (valid && this.isEmailValid) {
							this.nextStep();
						}
					});
				} else if (currentStep === 4) {
					this.$refs[step].validate().then(valid => {
						if (valid && this.isPartnerCodeValid) {
							this.nextStep();
						}
					});
				} else if(currentStep === 5) {
					this.handleFormSubmit(e);
				} else {
					this.$refs[step].validate().then(valid => {
						if(valid) {
							this.nextStep();
						}

					});
				}
			}

		  e.preventDefault();
	  },

	  getRandomPartnerCode() {
		  api.setLocale()
		  api.get(`/get-random-partner-code`)
				  .then(
						  success => {
							  this.user.partner_code = success.data.partnerCode;
						  },
						  error => {
							  this.$store.dispatch('alert/error', error, { root: true });
						  }
				  ).finally(() => {
		  });
	  }
  },
  mounted() {
    this.yearsData = generateYearsBetween(18, 100);
		this.fetchGendersOnPaper();
  },
	beforeMount() {
		if(router.currentRoute.query.verified) {
			this.isShowBeforeYouRegisterModal = false;
			this.currentStep = 7;
		}
	},
	beforeDestroy() {
	},

}
</script>

<style lang="scss" scoped>
</style>
