<template>
  <AdminPageFrame>
    <PageTitle>
      {{ $route.params.id ? trans('PAGE_TITLE:PostEdit') : trans('PAGE_TITLE:PostNew') }}
    </PageTitle>
    <t-card v-if="articleEdit" class="w-full">
      <div class="flex flex-col lg:flex-row w-full space-y-3 lg:space-y-0 mb-3">
        <FieldWrapper class="flex w-full lg:w-1/2"  :label="trans('LABEL:PostCategory')">
          <SelectField v-model="articleEdit.postCategory" :options="postCategoriesAdminData" :placeholder="''"/>
        </FieldWrapper>
        <div v-if="postCategoryIsAds" class="flex flex-row w-full lg:w-1/2 gap-x-3 lg:gap-x-6 lg:pl-6">
          <FieldWrapper class="w-1/2" :label="trans('LABEL:AdsFrom')">
            <t-datepicker
                dateFormat="Y-m-d H:i"
                userFormat="d F Y H:i"
                :weekStart="1"
                :lang="langLocale"
                :timepicker="true"
                v-model="articleEdit.adsFrom"
            />
          </FieldWrapper>
          <FieldWrapper class="w-1/2" :label="trans('LABEL:AdsTo')">
            <t-datepicker
                dateFormat="Y-m-d H:i"
                userFormat="d F Y H:i"
                :weekStart="1"
                :lang="langLocale"
                :timepicker="true"
                v-model="articleEdit.adsTo"
            />
          </FieldWrapper>
        </div>
      </div>
	    <div class="flex flex-row justify-between mb-3">
        <FieldWrapper class="flex w-1/2 lg:w-1/4 justify-start" :label="trans('LABEL:PostPublishedAt')">
          <t-datepicker
              dateFormat="Y-m-d H:i"
              userFormat="d F Y H:i"
              :weekStart="1"
              :lang="langLocale"
              :timepicker="true"
              v-model="articleEdit.publishedAtDate || new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 16)"
          />
        </FieldWrapper>
        <div class="flex w-1/2 items-end justify-end">
          <Button :text="trans('BUTTON:Save')" :loading="storePostLoading" @clicked="storePostAction"/>
        </div>
      </div>
	    <FieldWrapper :label="trans('LABEL:PostLanguage')">
		    <div class="flex flex-col lg:flex-row justify-evenly lg:justify-start w-full mt-2 lg:space-x-6 space-y-3 lg:space-y-0 items-start lg:items-center mb-3">
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCode" name="language_code" value="pl" />
				    <img :src="require(`@/assets/images/flags/pl.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:pl")}}</span>
			    </label>
          <div class="flex flex-row gap-1.5">
            <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
              <t-radio v-model="languageCode" name="language_code" value="en" />
              <img :src="require(`@/assets/images/flags/en.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
              <span>{{trans("LANGUAGE:en")}}</span>
            </label>
            <span>|</span>
            <Button
              variant="link"
              :loading="this.translationLanguageTo==='en' && translationLoading"
              :text="trans('BUTTON:Translate')"
              @clicked="translate('pl','en')"
              :disabled="this.translationLanguageTo!=='en' && translationLoading"
            />
          </div>
          <div class="flex flex-row gap-1.5">
            <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
              <t-radio v-model="languageCode" name="language_code" value="uk" />
              <img :src="require(`@/assets/images/flags/uk.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
              <span>{{trans("LANGUAGE:uk")}}</span>
            </label>
            <span>|</span>
            <Button
              variant="link"
              :loading="this.translationLanguageTo==='uk' && translationLoading"
              :text="trans('BUTTON:Translate')"
              @clicked="translate('pl','uk')"
              :disabled="this.translationLanguageTo!=='uk' && translationLoading"
            />
          </div>
		    </div>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:PostLanguageIsDefault')">
		    <div class="flex flex-col lg:flex-row justify-evenly lg:justify-start w-full mt-2 lg:space-x-6 space-y-3 lg:space-y-0 items-start lg:items-center mb-3">
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCodeDefault" name="is_default" value="pl" />
				    <img :src="require(`@/assets/images/flags/pl.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:pl")}}</span>
			    </label>
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCodeDefault" name="is_default" value="en" />
				    <img :src="require(`@/assets/images/flags/en.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:en")}}</span>
			    </label>
			    <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				    <t-radio v-model="languageCodeDefault" name="is_default" value="uk" />
				    <img :src="require(`@/assets/images/flags/uk.png`)" alt="flag" class="rounded-full border border-black h-6 w-6">
				    <span>{{trans("LANGUAGE:uk")}}</span>
			    </label>
		    </div>
	    </FieldWrapper>
	    <FieldWrapper class="mb-3" :label="trans('LABEL:PostTitle')">
		    <TextField v-model="articleEdit[languageCode].title" />
	    </FieldWrapper>
	    <FieldWrapper class="mb-3" :label="trans('LABEL:PostSlug')">
		    <TextField v-model="articleEdit[languageCode].slug"/>
	    </FieldWrapper>
	    <div class="flex flex-col lg:flex-row justify-evenly lg:justify-between w-full space-y-3 lg:space-y-0 lg:space-x-6 mb-3">
        <FieldWrapper class="w-full lg:w-1/2" :label="trans('LABEL:PostFeaturedImage')">
          <div class="flex flex-row">
            <div class="pr-3">
              <Picture
                id="imagePreview"
                classes="w-48 h-36 border border-grayed object-cover rounded"
                :src="articleEdit[languageCode].imageUrl || themeConfig.staticPhotos.emptyPlaceholder"
              />
            </div>
            <div>
              <input v-on:change="addPostPicture" type="file" />
            </div>
          </div>
        </FieldWrapper>
      </div>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostExcerpt')">
        <div class="excerpt-body">
	        <vue-editor
            class="custom-editor"
            ref="excerptEditor"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            v-model="articleEdit[languageCode].excerpt"
          />
        </div>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostBody')">
        <div class="post-body">
	        <vue-editor
            class="custom-editor"
            id="myQuillEditor"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            v-model="articleEdit[languageCode].body"
	        />
        </div>
      </FieldWrapper>
      <div class="flex flex-row w-full justify-end">
        <Button :text="trans('BUTTON:Save')" :loading="storePostLoading" @clicked="storePostAction"/>
      </div>
    </t-card>

      <Modal
        :showModal="showPostIncompleteModal"
        :header="trans('MODAL_TITLE:Attention')"
      >
        <template v-slot:content>
          <div class="lg:ml-3">
            <p>{{ trans('MODAL_DESCRIPTION:PostIncomplete1') }}</p>
            <p>{{ trans('MODAL_DESCRIPTION:PostIncomplete2') }}</p>
          </div>
        </template>
        <template v-slot:footer>
          <Button
            variant="buttonRed"
            :text="trans('BUTTON:Close')"
            @clicked="openPostIncompleteModal(false)"
          />
        </template>
      </Modal>

      <Modal
        :showModal="showTranslationExistsModal"
        :header="trans('MODAL_TITLE:Attention')"
      >
        <template v-slot:content>
          <div class="lg:ml-3">
            <p>{{ trans('MODAL_DESCRIPTION:TranslationExists1') }}</p>
            <p>{{ trans('MODAL_DESCRIPTION:TranslationExists2') }}</p>
          </div>
        </template>
        <template v-slot:footer>
          <Button
            variant="buttonRedOutlined"
            :text="trans('BUTTON:Translate')"
            @clicked="translate(translationLanguageFrom, translationLanguageTo, true)"
          />
          <Button
            variant="buttonRed"
            :text="trans('BUTTON:Cancel')"
            @clicked="openTranslationExistsModal(translationLanguageFrom, translationLanguageTo,false)"
          />
        </template>
      </Modal>

  </AdminPageFrame>

</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import {mapActions, mapGetters} from "vuex";
import TextField from "@/utils/crud/components/field-types/Text";
import PageTitle from "@/layouts/components/pages/PageTitle";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import SelectField from "@/utils/crud/components/field-types/Select";
import Button from "@/utils/crud/components/Button";
import api from "@/common/services/api";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {VueEditor} from "vue2-editor";
import ImageResize from '@taoqf/quill-image-resize-module';
import Modal from "@/utils/modal/Modal";
import themeConfig from "@/themeConfig";

export default {
  name: "AdminPostShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    SelectField,
    TextField,
    quillEditor,
	  VueEditor,
    Modal
  },
  data() {
    return {
      themeImages: themeConfig.staticPhotos,
      articleEdit: {
	      postCategory: null,
        adsFrom: null,
        adsTo: null,
        publishedAtDate: null,
				pl: {
          title: null, slug:null, excerpt: null, body : null,
          imageUrl: null, featuredImageUrl: null, thumbnailUrl: null, imageName: null
				},
	      en: {
          title: null, slug:null, excerpt: null, body : null,
          imageUrl: null, featuredImageUrl: null, thumbnailUrl: null, imageName: null
	      },
	      uk: {
          title: null, slug:null, excerpt: null, body : null,
          imageUrl: null, featuredImageUrl: null, thumbnailUrl: null, imageName: null
	      }
      },
	    customModulesForEditor: [
		    { alias: "imageResize", module: ImageResize }
	    ],
      isNewArticle: false,
	    editorSettings: {
		    modules: {
			    imageResize: {
            modules: [ 'Resize', 'DisplaySize' ]
          }
		    }
	    },
	    languageCode: 'pl',
	    languageCodeDefault: 'pl',
	    windowHeight: 0,
      showTranslationExistsModal: false,
      showPostIncompleteModal: false,
      translationLanguageFrom: '',
      translationLanguageTo: '',
      translationLoading: false,
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        this.isNewArticle = val === undefined;
        if(val) {
          this.fetchAdminPostShow(val);
        }
      }
    },
    postLoaded(val) {
      if(val) {
	      this.articleEdit = structuredClone(this.adminPostShow);
	      this.articleEdit.postCategory = this.adminPostShow.postCategory.id;
	      this.languageCode = this.adminPostShow.languageCode
	      this.languageCodeDefault = this.adminPostShow.languageCodeDefault;
      }
    },
	  postCategoriesData: {
		  immediate: true,
		  handler(val) {
			  if(val) {
				  this.$set(this.articleEdit, 'postCategory', this.postCategoriesData.find(el => el.slug === 'news').id);
			  }
		  }
	  },
    languageCode(newLangCode, oldLangCode) {
      const langData = this.articleEdit[newLangCode];
      const defaultLangData = this.articleEdit[this.languageCodeDefault];
      const placeholderImage = this.themeConfig.staticPhotos.emptyPlaceholder; // Placeholder

      if (!langData.imageUrl) {
        if (langData.featuredImageUrl ) {
          this.$set(langData, 'imageUrl', langData.featuredImageUrl);
        }
        // Sprawdzamy, czy w nowym języku nie ma przypisanego obrazka.
        else if (defaultLangData.imageUrl) {
          // Jeśli obrazek w nowym języku nie istnieje, kopiujemy obrazek z języka domyślnego
          this.$set(langData, 'imageUrl', defaultLangData.imageUrl);
        }
        // Jeśli obrazek nie istnieje, przypisujemy placeholder
        else
          this.$set(langData, 'imageUrl', placeholderImage);
      }

      // Odświeżenie widoku po zmianie języka
      this.$nextTick(() => {
        window.scrollTo(0, this.windowHeight);
      });
    },

  },
  computed: {
    ...mapGetters('admin', ['adminPostShow', 'postLoaded', 'storePostLoading', 'savedPost']),
    ...mapGetters('dictionaries', ['postCategoriesAdminData']),
    ...mapGetters('lang', ['langLocale']),
    themeConfig() {
      return themeConfig
    },
    postCategoryIsAds() {
			if (this.articleEdit.postCategory && this.postCategoriesAdminData.length > 0) {
				let category = this.postCategoriesAdminData.find(x => x.id === Number(this.articleEdit.postCategory))
				return category.slug === 'ads';
			}
	  }
  },
  methods: {
    ...mapActions('admin', ['fetchAdminPostShow', 'storeAdminPost', 'updateAdminPost']),
    ...mapActions('dictionaries', ['fetchPostCategoriesAdminData']),
	  handleScroll () {
			this.windowHeight = window.scrollY;
	  },

    addPostPicture(event) {
      const imageFile = event.target.files[0];

      if (imageFile.size > 2 * 1024 * 1024) {
        alert('File is too large (2MB max).');
        return;
      }

      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!allowedTypes.includes(imageFile.type)) {
        alert('Invalid file format! Allowed: jpg, png, gif, webp.');
        return;
      }

      const imageURL = URL.createObjectURL(imageFile);

      this.$set(this.articleEdit[this.languageCode], 'imageName', imageFile.name);
      this.$set(this.articleEdit[this.languageCode], 'imageUrl', imageURL);
      this.$set(this.articleEdit[this.languageCode], 'featuredImageUrl', imageFile);

      // Jeśli wybrany język nie ma przypisanego obrazka, kopiujemy obrazek z języka domyślnego
      if (!this.articleEdit[this.languageCode].imageUrl && this.articleEdit[this.languageCodeDefault].imageUrl) {
        this.$set(this.articleEdit[this.languageCode], 'imageUrl', this.articleEdit[this.languageCodeDefault].imageUrl);
      }

      // Jeśli obrazek nie istnieje, przypisujemy placeholder
      if (!this.articleEdit[this.languageCode].imageUrl) {
        this.$set(this.articleEdit[this.languageCode], 'imageUrl', this.themeConfig.staticPhotos.emptyPlaceholder);
      }

      // Zaktualizowanie podglądu obrazka w UI
      const imagePreview = document.getElementById('imagePreview');
      if (imagePreview) {
        imagePreview.src = imageURL;
        imagePreview.style.display = 'block'; // In case the preview is hidden initially
      }
    },

    storePostAction() {
      let data = new FormData();
      data.append('languageCode', this.languageCode);
      data.append('languageCodeDefault', this.languageCodeDefault);

      if (this.articleEdit.publishedAtDate) {
        data.append('published_at', this.articleEdit.publishedAtDate);
      }

      data.append('post_category_id', this.articleEdit.postCategory);

      const languages = ['pl', 'en', 'uk'];
      const defaultLang = 'pl';

      languages.forEach(lang => {
        const langData = this.articleEdit[lang];

        if (langData.title || langData.excerpt || langData.body || langData.slug) {
          data.append(`${lang}[title]`, langData.title ?? '');
          data.append(`${lang}[slug]`, langData.slug ?? '');
          data.append(`${lang}[excerpt]`, langData.excerpt ?? '');
          data.append(`${lang}[body]`, langData.body ?? '');

          // Handle featured image
          const imageUrl = langData.featuredImageUrl || this.articleEdit[defaultLang].featuredImageUrl;
          if (imageUrl) {
            data.append(`${lang}[featuredImageUrl]`, imageUrl);
          }
        }
      });

      if (this.postCategoryIsAds) {
        data.append('ads_from', this.articleEdit.adsFrom);
        data.append('ads_to', this.articleEdit.adsTo);
      }

      if (this.isNewArticle) {
        this.storeAdminPost(data);
      } else {
        this.updateAdminPost({ data: data, postId: this.articleEdit.id });
      }
    },

    openPostIncompleteModal(value) {
      this.showPostIncompleteModal = value
    },

    openTranslationExistsModal(lang_from, lang_to, value) {
      this.showTranslationExistsModal = value
      this.translationLanguageFrom = lang_from
      this.translationLanguageTo = lang_to
    },

    generateSlug(title, language = 'en') {
      // Obsługa ukraińskiego (cyrylica)
      if (language === 'uk') {
        return title
          .toLowerCase()                                                // Zamiana na małe litery
          .trim()                                                       // Usunięcie białych znaków
          .replace(/[^а-яёіїєґ0-9\s-]/g, '')     // Usunięcie znaków specjalnych (zachowanie cyrylicy)
          .replace(/[\s-]+/g, '-')               // Zamiana wielokrotnych spacji lub myślników na pojedynczy myślnik
          .replace(/^-+|-+$/g, '');              // Usunięcie myślników z początku i końca
      }
      // Domyślny język (angielski)
      return title
        .normalize('NFD')                                             // Normalizacja Unicode (usunięcie znaków diakrytycznych)
        .replace(/[\u0300-\u036f]/g, '')                              // Usunięcie znaków diakrytycznych
        .toLowerCase()                                                // Zamiana na małe litery
        .trim()                                                       // Usunięcie białych znaków
        .replace(/[^a-z0-9\s-]/g, '')          // Usunięcie znaków specjalnych (zachowanie tylko liter i cyfr)
        .replace(/[\s-]+/g, '-')               // Zamiana wielokrotnych spacji lub myślników na pojedynczy myślnik
        .replace(/^-+|-+$/g, '');              // Usunięcie myślników z początku i końca
    },

    async translate(lang_from, lang_to, ignoreExisting) {
      const sourceData = this.articleEdit[lang_from] || {};
      const targetData = this.articleEdit[lang_to] || {};

      // Sprawdzenie, czy dane źródłowe są kompletne
      if (sourceData.title && sourceData.slug && sourceData.excerpt && sourceData.body) {

        // Sprawdzenie, czy docelowy język już istnieje i czy ignorować istniejące dane
        if (!ignoreExisting && (targetData.title || targetData.slug || targetData.excerpt || targetData.body)) {
          this.openTranslationExistsModal(lang_from, lang_to, true);
        } else {
          this.openTranslationExistsModal(lang_from, lang_to,false);
          this.translationLoading = true;

          try {
            const response = await api.post('/translate', {
              lang_from: lang_from,
              lang_to: lang_to,
              message: [{
                'title': sourceData.title,
                'excerpt': sourceData.excerpt,
                'body': sourceData.body
              }]
            });

            const translated = response.translated[0];
            const translatedSlug = this.generateSlug(translated.title, lang_to);

            // Aktualizacja danych docelowych
            this.$set(this.articleEdit, lang_to, {
              title: translated.title,
              slug: translatedSlug,
              excerpt: translated.excerpt,
              body: translated.body,
              //imageUrl: sourceData.imageUrl // Kopiowanie obrazka z języka źródłowego
            });
          } catch (error) {
            console.error('Error communicating with ChatGPT:', error);
          }

          this.translationLoading = false;
          this.translationLanguageFrom = null;
          this.translationLanguageTo = null;
        }
      } else {
        this.openPostIncompleteModal(true);
      }
    },
  },

  mounted() {
		this.fetchPostCategoriesAdminData();
  },
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted () {
		window.removeEventListener('scroll', this.handleScroll);
	},
}
</script>

<style lang="scss">
.post-body {
  .ql-editor {
      height: 500px;
  }
}
.excerpt-body {
  .ql-editor {
      height: 100px;
  }
}

.ql-video {
  width: 100%;
  height: 400px;
  padding: 16px 0;
}

.custom-editor .ql-toolbar {
  @apply border-l border-r border-t border-grayed rounded-t;
}

.custom-editor .ql-container {
  @apply border border-grayed rounded-b bg-grayedLight;
}

</style>