<template>
  <component
    :is="iconComponent"
    role="img"
    :class="[color, {'inline-block':!!!noInlineBlock}]"
    :style="{width: width, height: height}"
    @click="$emit('clicked')"
  />
</template>

<script>
const icons = {
	linkedin: require('./icons/LinkedinIcon.vue').default,
  adminPanel: require('./icons/AdminPanelIcon').default,
  arrowBarDown: require('./icons/ArrowBarDownIcon').default,
  arrowBarUp: require('./icons/ArrowBarUpIcon').default,
  arrowsCollapse: require('./icons/ArrowsCollapseIcon').default,
  arrowsExpand: require('./icons/ArrowsExpandIcon').default,
  arrowsPopulate: require('./icons/ArrowsPopulateIcon').default,
  back: require('./icons/BackIcon').default,
  ban: require('./icons/BanIcon').default,
  bell: require('./icons/BellIcon').default,
  bug: require('./icons/BugIcon').default,
  chat: require('./icons/ChatIcon.vue').default,
  chatDots: require('./icons/ChatDotsIcon.vue').default,
  chatFilled: require('./icons/ChatFilledIcon.vue').default,
  chatLines: require('./icons/ChatLinesIcon.vue').default,
  chats: require('./icons/ChatsIcon.vue').default,
  chevronDown: require('./icons/ChevronDownIcon').default,
  chevronDownBig: require('./icons/ChevronDownBigIcon').default,
  chevronLeft: require('./icons/ChevronLeftIcon').default,
  chevronRight: require('./icons/ChevronRightIcon').default,
  chevronUp: require('./icons/ChevronUpIcon').default,
  cloudDownloadFilled: require('./icons/CloudDownloadFilledIcon.vue').default,
  compass: require('./icons/CompassIcon.vue').default,
  crosshair: require('./icons/CrossHairIcon.vue').default,
  dotsHorizontal: require('./icons/DotsHorizontalIcon').default,
  dotsVertical: require('./icons/DotsVerticalIcon').default,
  edit: require('./icons/EditIcon').default,
  editError: require('./icons/EditErrorIcon').default,
  editPencil: require('./icons/EditPencilIcon').default,
  emojiAngry: require('./icons/EmojiAngryIcon').default,
  emojiHeart: require('./icons/EmojiHeartIcon').default,
  emojiNeutral: require('./icons/EmojiNeutralIcon').default,
  emojiWink: require('./icons/EmojiWinkIcon').default,
  error: require('./icons/ErrorIcon').default,
  exclamation: require('./icons/ExclamationIcon').default,
  exit: require('./icons/ExitIcon').default,
  facebook: require('./icons/FacebookIcon.vue').default,
  faq: require('./icons/FaqIcon').default,
  favouriteContact:require('./icons/FavouriteContactIcon').default,
  filters: require('./icons/FiltersIcon').default,
  fire: require('./icons/FireIcon').default,
  funnel:require('./icons/FunnelIcon').default,
  gallery: require('./icons/GalleryIcon').default,
  hamburger: require('./icons/HamburgerIcon').default,
  heart: require('./icons/HeartIcon').default,
  heartFilled: require('./icons/HeartFilledIcon').default,
  history: require('./icons/HistoryIcon').default,
  hourglass: require('./icons/HourglassIcon').default,
  incognito: require('./icons/IncognitoIcon').default,
  info: require('./icons/InfoIcon').default,
  infoFilled: require('./icons/InfoFilledIcon').default,
  instagram: require('./icons/InstagramIcon.vue').default,
  invisible: require('./icons/InvisibleIcon').default,
  lockFilled: require('./icons/LockFilledIcon').default,
  lockOpen: require('./icons/LockOpenedIcon').default,
  magnifier: require('./icons/MagnifierIcon').default,
  map: require('./icons/MapIcon.vue').default,
  newContacts: require('./icons/NewContactsIcon').default,
  newContactsFilled: require('./icons/NewContactsFilledIcon').default,
  news: require('./icons/NewsIcon.vue').default,
  palette: require('./icons/PaletteIcon').default,
  pin: require('./icons/PinIcon.vue').default,
  profile: require('./icons/ProfileIcon').default,
  profilePicture: require('./icons/ProfilePictureIcon').default,
  profilePictureCrossedFilled: require('./icons/ProfilePictureCrossedFilledIcon').default,
  profilePictureFilled: require('./icons/ProfilePictureFilledIcon').default,
  profilePictureFilledBlack: require('./icons/ProfilePictureFilledBlackIcon').default,
  profilePictureTickedFilled: require('./icons/ProfilePictureTickedFilledIcon').default,
  profilePictureTickedFilledColored: require('./icons/ProfilePictureTickedFilledColoredIcon').default,
  profileX: require('./icons/ProfileXIcon').default,
  profileXFilled: require('./icons/ProfileXFilledIcon').default,
  removeUserFilled: require('./icons/RemoveUserFilledIcon').default,
  reset: require('./icons/ResetIcon').default,
  search: require('./icons/SearchIcon').default,
  sendMessage: require('./icons/SendMessageIcon').default,
  settings: require('./icons/SettingsIcon').default,
  settingsFilled: require('./icons/SettingsFilledIcon').default,
  shape: require('./icons/ShapeIcon').default,
  shapeFilled: require('./icons/ShapeFilledIcon').default,
  shuffle: require('./icons/ShuffleIcon').default,
  smile: require('./icons/SmileIcon').default,
  star: require('./icons/StarIcon').default,
  starFilled: require('./icons/StarFilledIcon').default,
  start: require('./icons/StartIcon').default,
  stop: require('./icons/StopIcon').default,
  tiktok: require('./icons/TikTokIcon.vue').default,
  trash: require('./icons/TrashIcon').default,
  union: require('./icons/UnionIcon').default,
  www: require('./icons/WwwIcon.vue').default,
  x: require('./icons/XIcon').default,
  youtube: require('./icons/YoutubeIcon').default,
  zoom: require('./icons/ZoomIcon').default,
}

export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    },
    color: {
      type: String,
      default: 'fill-current hover:fill-redAccent',
    },
    width: {
      type: [Number, String],
      //default: 16,
    },
    height: {
      type: [Number, String],
      //default: 16,
    },
    noInlineBlock: {
      type: [Boolean, String],
    }
  },

  computed: {
    iconComponent() {
      return icons[this.name]
    }
  }
}
</script>

<style lang="scss">

.fill-badgeError {
  fill: theme('colors.badgeError');
}

.fill-black {
  fill: theme('colors.black')
}

.fill-redAccent {
  fill: theme('colors.redAccent');
}

.fill-redAccentLight {
  fill: theme('colors.redAccentLight');
}

.fill-badgeInfo {
  fill: theme('colors.badgeInfo')
}

.fill-badgeSuccess {
  fill: theme('colors.badgeSuccess')
}

.fill-current {
  fill: theme('colors.black')
}

.fill-white {
  fill: theme('colors.white')
}

.fill-grayed {
  fill: theme('colors.grayed')
}
.fill-grayedLight {
  fill: theme('colors.grayedLight')
}

.fill-transparent {
	fill: theme('colors.transparent')
}
</style>
