<template>
  <PageContainer>
    <PageTitle>
      <template v-if="userData">
        <div class="flex flex-row items-center gap-3">
          <div>
            {{ trans('PAGE_TITLE:ProfileEdit') }}
          </div>
          <VTooltip>
            <span class="flex flex-row items-center">
              <Button
                  variant="link"
                  @clicked="openBeforeFillingYourProfileModal(true)"
              >
                <base-icon  name="info" width="20" height="20">
                </base-icon>
              </Button>
            </span>
            <template #popper>
              {{ trans('LABEL:BeforeFillingYourProfile') }}
            </template>
          </VTooltip>
        </div>
        <BadgeFrame v-if="userData && (userData.isBanned || !userData.isSetupComplete || !userData.descriptionEdit.description || !userData.isGalleryComplete || userData.isHidden)"
        >
          <Badge tagName="span" variant="badgeError" v-if="userData && userData.isBanned">
            {{ trans('LABEL:AccountBlocked') }}
          </Badge>
          <Badge tagName="span" variant="badgeError" v-if="userData && !userData.isSetupComplete">
            {{ trans('LABEL:ProfileDetailsIncomplete') }}
          </Badge>
          <Badge tagName="span" variant="badgeError" v-if="userData && userData.descriptionEdit && !userData.descriptionEdit.description">
            {{ trans('LABEL:ProfileDescriptionIncomplete') }}
          </Badge>
          <Badge tagName="span" variant="badgeError" v-if="userData && !userData.isGalleryComplete">
            {{ trans('LABEL:GalleryIsIncomplete') }}
          </Badge>
          <Badge tagName="span" variant="badgeInfo" v-if="userData && userData.isHidden">
            {{ trans('LABEL:ProfileHidden') }}
          </Badge>
        </BadgeFrame>
      </template>
    </PageTitle>
    <PageContentWithSidebar>
      <Sidebar>
        <Loader v-show="!galleryOnceLoaded && picturesLoadingCounter > 0"
          class="w-full lg:w-sidebar lg:h-gallery-picture-featured"
          :class="getHeightOfScreenWidth(1)"
        />
        <GalleryProfile
          v-show="galleryOnceLoaded || (picturesLoadingCounter === 0 || picturesLoadingCounter === null)"
          class="w-full lg:w-sidebar px-3 lg:px-0"
          :user-data="userData"
          :is-user-owner="true"
        />
      </Sidebar>
	    <Loader v-if="!isMobile && profileLoading" class="w-main-container h-sidebar"/>
      <t-card v-else class="flex w-full lg:w-main-container bg-white p-3 lg:p-6">
        <div class="w-full" v-if="userData">
          <!-- User name -->
          <div class="transform ease-out duration-200 cursor-pointer
            hover:opacity-70 lg:hover:scale-101 hover:text-redAccent"
            @click="openBasicEditModal(true)"
          >
            <div class="w-full flex flex-row justify-between mb-2">
              <div class="w-full flex flex-row gap-2 items-end lg:items-center text-lg font-semibold text-pretty">
                <t-button
                  noAction
                  variant="iconProfileBigger"
                  @clicked="openBasicEditModal(true)"
                  class="text-left"
                >
                  {{ userData.name }}
                </t-button>
                <div class="w-min flex flex-row items-center mb-1 gap-2">
                  <base-icon
                    class="w-4 h-4"
                    :name="!areBasicFilled ? 'editError' : 'edit'"
                    :color="!areBasicFilled ? 'fill-badgeError' : 'fill-grayed'"
                  />
                </div>
              </div>
            </div>
            <!-- Gender, age, orientation, preference-->
            <div class="text-base  leading-normal">
              <div class="flex flex-wrap flex-row justify-start px-1.5 lg:px-3 mb-2" >
                <template v-if="userData.gender.isMatchingId">
                  <span>{{ userData.gender.desc }}</span>,&nbsp;
                </template>
                <template v-else>
                  <VTooltip>
                    <base-icon class="inline-block" name="shuffle"/> <span>{{ userData.gender.desc }}</span>,&nbsp;
                    <template #popper>
                      {{ trans('TOOLTIP:GenderDeclaredDescription') }}
                    </template>
                  </VTooltip>
                </template>
                <span>{{ userData.age }}</span>,&nbsp;
                <span :class="{'text-badgeError': userData.orientation.id === null}">{{ userData.orientation.desc }}</span>,&nbsp;
                <span :class="{'text-badgeError': userData.preference.id === null}">{{ userData.preference.desc }}{{ userData.isCoupleProfile || userData.acceptBi ? ',&nbsp;' : '' }}</span>
                <span v-if="userData.isCoupleProfile">{{ trans('LABEL:isCoupleProfile') }}{{ userData.acceptBi ? ',&nbsp;' : '' }}</span>
                <span v-if="userData.acceptBi">{{ trans('LABEL:isAcceptingBi') }}</span>
              </div>
            </div>
          </div>

          <!-- Appearance -->
          <div class="transform ease-out duration-200 cursor-pointer hover:opacity-70 lg:hover:scale-101 hover:text-redAccent"
            @click="openAppearanceModal(true)"
          >
            <t-button
                noAction
                variant="iconProfile"
                @clicked="openAppearanceModal(true)"
            >
              {{ trans('LABEL:Appearance') }}
              <base-icon
                :name="!areAppearanceFilled ? 'editError' : 'edit'"
                :color="!areAppearanceFilled ? 'fill-badgeError' : 'fill-grayed'"
                :hover="!areAppearanceFilled ? 'fill-badgeError' : 'fill-current'"
              />
            </t-button>

            <div class="flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3">
                <span :class="{'text-badgeError': userData.height.value === null}">{{ userData.height.desc }}</span>,
                <span :class="{'text-badgeError': userData.weight.value === null}">{{ userData.weight.desc }}</span>,
                <span :class="{'text-badgeError': userData.shape.id === null}">{{ userData.shape.desc }}</span>
              </div>
            </div>
          </div>

          <!-- Status -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent" @click="openStatusModal(true)">
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal">
              <t-button
                  variant="iconProfile"
                  @clicked="openStatusModal(true)"
              >
                {{ trans('LABEL:Status') }}
                <base-icon
                  :name="!areStatusesFilled ? 'editError' : 'edit'"
                  :color="!areStatusesFilled ? 'fill-badgeError' : 'fill-grayed'"
                />
              </t-button>
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3">
                <span :class="{'text-badgeError': userData.statusMarital.id === null}">{{ userData.statusMarital.desc }}</span>,
                <span :class="{'text-badgeError': userData.statusParental.id === null}">{{ userData.statusParental.desc }}</span>
              </div>
            </div>
          </div>

          <!-- Location -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent" @click="openLocationModal(true)">
            <template v-if="userData.locationOwner.descRegion">
              <div class="flex flex-row items-center gap-2 font-semibold text-s leading-normal">
                <t-button
                    variant="iconProfile"
                    @clicked="openLocationModal(true)"
                >
                  {{ trans('LABEL:Location') }}
                  <template v-if="!userData.location.isLocationVisible">
                    <VTooltip>
                      <base-icon class="w-4 h-4" name="invisible" color="fill-badgeNeutral"/>
                      <template #popper>
                        {{ trans('TOOLTIP:LocationInvisible') }}
                      </template>
                    </VTooltip>
                  </template>
                  <base-icon :color="!userData.locationOwner.id ? 'fill-badgeError' : 'fill-grayed'"
                    :name="!userData.locationOwner.id ? 'editError' : 'edit'"
                  />
                </t-button>
              </div>
              <div class="flex flex-row items-center gap-2 px-1.5 lg:px-3 text-base  leading-normal mb-2">
                <span class="flex flex-row items-center gap-2"
                  :class="{'text-badgeError': !userData.locationOwner.id}"
                >
                  {{ userData.locationOwner.descRegion }}
                </span>
              </div>
            </template>
          </div>

          <!-- SearchWho -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent" @click="openLookingForModal(true)">
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal">
              <t-button
                  :variant="'iconProfile'"
                  @clicked="openLookingForModal(true)"
              >
                {{ trans('LABEL:LookingFor') }}
                <base-icon :color="!userData.searchWhos.desc ? 'fill-badgeError' : 'fill-grayed'" :name="!userData.searchWhos.desc ? 'editError' : 'edit'" />
              </t-button>
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <template v-if="userData.searchWhos.desc">
                <div class="px-1.5 lg:px-3">
                  {{ userData.searchWhos.desc }}
                </div>
              </template>
              <template v-else>
                <div class="px-1.5 lg:px-3 text-badgeError">
                  {{ trans('LABEL:lookingForNoYet') }}
                </div>
              </template>
            </div>
          </div>


          <!-- SearchWhats -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent" @click="openSearchingWhatsModal(true)">
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal">
              <t-button
                  :variant="'iconProfile'"
                  @clicked="openSearchingWhatsModal(true)"
              >
                {{ trans('LABEL:InterestedIn') }}
                <base-icon :color="!userData.searchWhats.desc ? 'fill-badgeError' : 'fill-grayed'" :name="!userData.searchWhats.desc ? 'editError' : 'edit'" />
              </t-button>
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <template v-if="userData.searchWhats.desc">
                <div class="px-1.5 lg:px-3">
                  {{ userData.searchWhats.desc }}
                </div>
              </template>
              <template v-else>
                <div class="px-1.5 lg:px-3 text-badgeError">
                  {{  trans('LABEL:interestedInNoYet') }}
                </div>
              </template>
            </div>
          </div>

          <!-- Stimulants -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent" @click="openStimulantsModal(true)">
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal">
              <t-button
                  variant="iconProfile"
                  @clicked="openStimulantsModal(true)"
              >
                {{ trans('LABEL:Stimulants') }}
                <base-icon :color="!areStimulantsFilled ? 'fill-badgeError' : 'fill-grayed'" :name="!areStimulantsFilled ? 'editError' : 'edit'" />
              </t-button>
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3 gap-1 flex flex-col">
                <span v-if="userData.stimulantsReg" class="" >{{ userData.stimulantsReg}}</span>
                <span v-if="userData.stimulantsOcc" class="" >{{ userData.stimulantsOcc}}</span>
                <span v-if="userData.stimulantsNev" class="" >{{ userData.stimulantsNev}}</span>
                <span v-if="userData.stimulantsNan" class="" >{{ userData.stimulantsNan}}</span>
                <span v-if="userData.stimulantsUnk" class=" text-badgeError" >{{ userData.stimulantsUnk}}</span>
              </div>
            </div>
          </div>

          <!-- Pets -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent" @click="openPetsModal(true)">
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal">
              <t-button
                  variant="iconProfile"
                  @clicked="openPetsModal(true)"
                  class="fill-grayed"
              >
                {{ trans('LABEL:ApproachPetting') }}
                <base-icon :color="!arePetsFilled ? 'fill-badgeError' : 'fill-grayed'" :name="!arePetsFilled ? 'editError' : 'edit'" />
              </t-button>
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start mb-2">
              <div class="px-1.5 lg:px-3 whitespace-nowrap">
                <span class="whitespace-nowrap" :class="{'text-badgeError': userData.approachPetting.id === null}">{{ userData.approachPetting.desc }}</span>
              </div>
            </div>
          </div>

          <!-- Description -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent"
               @click="openDescriptionModal(true)">
            <div class="inline-flex items-center gap-2 font-semibold text-s leading-normal">
              <t-button
                  :variant="'iconProfile'"
                  @clicked="openDescriptionModal(true)"
              >
                {{ trans('LABEL:Description') }}
                <template v-if="userData.isDescriptionSpicy">
                  <VTooltip>
                    <base-icon class="w-4 h-4" name="fire" color="fill-badgeNeutral"/>
                    <template #popper>
                      {{ trans('LABEL:DescriptionSpicy') }}
                    </template>
                  </VTooltip>
                </template>
                <span>
                  <template v-if="!userData.description">
                    <base-icon name="editError" :color="'fill-badgeError'"/>
                  </template>
                  <template v-else-if="userData.descriptionEdit.descriptionNewPending && !userData.description">
                    <base-icon name="edit" :color="'fill-badgeInfo'" />
                  </template>
                  <template v-else>
                    <base-icon name="edit" :color="'fill-grayed'" />
                  </template>
                </span>
                <BadgeFrame v-if="userData.descriptionStatus.descriptionMessage">
                  <Badge tagName="span" :variant="userData.descriptionStatus.color"
                  >
                    {{ trans(userData.descriptionStatus.descriptionMessage)}}
                  </Badge>
                </BadgeFrame>
              </t-button>

            </div>
            <div class="px-1.5 lg:px-3 text-base  leading-normal description-text mb-2">
              <template v-if="userData.description">
                <span v-html="userData.description">
                </span>
              </template>
              <template v-else-if="userData.descriptionEdit.descriptionNewPending">
                <span class="text-grayed">
                  <span v-html="userData.descriptionEdit.descriptionNew"></span>
                </span>
              </template>
              <template v-else>
                <span class="text-badgeError">
                  {{ trans('LABEL:descriptionNoYet') }}
                </span>
              </template>
            </div>
          </div>

          <!-- Experience -->
          <div class="lg:hover:scale-101 transform ease-out duration-200 cursor-pointer hover:opacity-70 hover:text-redAccent mb-2"
            @click="openExperiencesModal(true)"
          >
            <div class="inline-flex gap-2 items-center font-semibold text-s leading-normal">
              <t-button
                  variant="iconProfile"
                  @clicked="openExperiencesModal(true)"
              >
                {{ trans('LABEL:Experience') }}
                <base-icon :color="!isAnyExperience ? 'fill-badgeError' : 'fill-grayed'" :name="!isAnyExperience ? 'editError' : 'edit'" />
              </t-button>
            </div>
            <div class="text-base  leading-normal flex flex-wrap flex-row justify-start"
              v-if="userData.experiences"
            >
              <div class="flex flex-col flex-wrap gap-y-2">
                <template v-if="isAnyExperience">
                  <template
                      v-if="userData.experiences['known'].length > 0"
                  >
                    <div class="px-1.5 lg:px-3 flex flex-row flex-wrap gap-x-3 gap-y-1">
                      <span
                          class="inline-flex items-center justify-start"
                          v-for="experience in userData.experiences['known']"
                      >
                        <base-icon name="emojiHeart" class="h-5 w-5 mr-2"/>
                        {{ experience.name }}
                      </span>
                    </div>
                  </template>
                  <template v-if="userData.experiences['known'].length > 0
                    && userData.experiences['curious'].length > 0"
                  >
                    <hr class="mx-3 w-1/2"/>
                  </template>
                  <template
                      v-if="userData.experiences['curious'].length > 0"
                  >
                    <div class="px-1.5 lg:px-3 flex flex-row flex-wrap gap-x-3 gap-y-1">
                      <span
                          class="inline-flex items-center justify-start"
                          v-for="experience in userData.experiences['curious']"
                      >
                      <base-icon name="emojiWink" class="h-5 w-5 mr-2"/>
                        {{ experience.name }}
                      </span>
                    </div>
                  </template>
                  <template v-if="(userData.experiences['known'].length > 0
                    || userData.experiences['curious'].length > 0)
                    && userData.experiences['noway'].length > 0"
                  >
                    <hr class="mx-3 w-1/2"/>
                  </template>
                  <template
                      v-if="userData.experiences['noway'].length > 0"
                  >
                    <div class="px-1.5 lg:px-3 flex flex-row flex-wrap gap-x-3 gap-y-1">
                      <span
                          class="inline-flex items-center justify-start"
                          v-for="experience in userData.experiences['noway']"
                      >
                        <base-icon name="emojiAngry" class="h-5 w-5 mr-2"/>
                        {{ experience.name }}
                      </span>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="px-1.5 lg:px-3 text-badgeError">
                    {{ trans('LABEL:experiencesNoYet') }}
                  </div>
                </template>
              </div>
            </div>
          </div>

        </div>
      </t-card>
    </PageContentWithSidebar>

    <!--  BASIC INFORMATION MODAL  -->
    <Modal
      v-if="dataEditModals.basicInformationModal"
      :show-modal="dataEditModals.basicInformationModal"
      @clicked="openBasicEditModal(false)"
      :header="trans('MODAL_TITLE:BasicInformation')"
      @onConfirm="editRequest"
      :loading="profileUpdating"
    >
      <template v-slot:content>
        <BasicInformationForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <!--  APPEARANCE MODAL  -->
    <Modal
      v-if="dataEditModals.appearanceModal"
      :show-modal="dataEditModals.appearanceModal"
      @clicked="openAppearanceModal(false)"
      @onConfirm="editRequest"
      :header="trans('MODAL_TITLE:Appearance')"
      :loading="profileUpdating"
    >
      <template v-slot:content>
        <AppearanceForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <!--  STATUS MODAL  -->
    <Modal
        v-if="dataEditModals.statusModal"
        :show-modal="dataEditModals.statusModal"
        @clicked="openStatusModal(false)"
        @onConfirm="editRequest"
        :header="trans('MODAL_TITLE:Status')"
        :loading="profileUpdating"
    >
      <template v-slot:content>
        <StatusForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

	  <!--  STIMULANTS MODAL  -->
	  <Modal
			  v-if="dataEditModals.stimulantsModal"
			  :show-modal="dataEditModals.stimulantsModal"
			  @clicked="openStimulantsModal(false)"
			  @onConfirm="editRequest"
			  :header="trans('MODAL_TITLE:Stimulants')"
			  :loading="profileUpdating"
	  >
		  <template v-slot:content>
			  <StimulantForm :send-request="sendRequestStatus" :user-data="userData" />
		  </template>
	  </Modal>

    <!--  PETS MODAL  -->
    <Modal
        v-if="dataEditModals.petsModal"
        :show-modal="dataEditModals.petsModal"
        @clicked="openPetsModal(false)"
        @onConfirm="editRequest"
        :header="trans('MODAL_TITLE:Pets')"
        :loading="profileUpdating"
    >
      <template v-slot:content>
        <PetForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <!--  LOOKING FOR MODAL  -->
    <Modal
        v-if="dataEditModals.lookingForModal"
        :show-modal="dataEditModals.lookingForModal"
        @clicked="openLookingForModal(false)"
        @onConfirm="editRequest"
        :header="trans('LABEL:LookingFor')"
        :loading="profileUpdating"
    >
      <template v-slot:content>
        <LookingForForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <!--  SEARCHING WHATS MODAL  -->
    <Modal
        v-if="dataEditModals.searchingWhatsModal"
        :show-modal="dataEditModals.searchingWhatsModal"
        @clicked="openSearchingWhatsModal(false)"
        @onConfirm="editRequest"

        :header="trans('LABEL:InterestedIn')"
        :loading="profileUpdating"
    >
      <template v-slot:content>
        <SearchingWhatsForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <!--  LOCATION MODAL  -->
    <Modal
      v-if="dataEditModals.locationModal"
      :show-modal="dataEditModals.locationModal"
      @clicked="openLocationModal(false)"
      @onConfirm="editRequest"
      :header="trans('MODAL_TITLE:Location')"
      :loading="profileUpdating"
    >
      <template v-slot:content>
        <LocationForm :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <!--  DESCRIPTION MODAL  -->
    <Modal
      variant="basic"
      v-if="dataEditModals.descriptionModal"
      :show-modal="dataEditModals.descriptionModal"
      @clicked="openDescriptionModal(false)"
      @onConfirm="editRequest"
      :loading="profileUpdating"
      :confirm-disabled="isConfirmDisabled"
    >
      <template v-slot:header>
        <div class="flex flex-row justify-between">
          <span class="inline-flex items-center gap-2">
            {{ trans('MODAL_TITLE:Description') }}
            <template v-if="userData.isDescriptionSpicy">
              <VTooltip>
                <base-icon name="fire" color="fill-badgeNeutral"/>
                <template #popper>
                  {{ trans('LABEL:DescriptionSpicy') }}
                </template>
              </VTooltip>
            </template>
            <Button
              variant="link"
              @clicked="toggleHint()"
            >
              <base-icon :name="isHintDisplayed?'infoFilled':'info'">
              </base-icon>
            </Button>
          </span>
          <div class="flex flex-col lg:flex-row items-center gap-2">
            <div v-if="userData.descriptionEdit" class="flex flex-row space-x-4">
              <BadgeFrame>
                <template v-if = "userData.descriptionEdit.descriptionNew
                  && userData.descriptionEdit.description !== userData.descriptionEdit.descriptionNew
                  && descriptionNewBeforeUpdate !== userData.descriptionEdit.descriptionNew
                  && (
                    !stringContainsString(userData.descriptionEdit.description,userData.descriptionEdit.descriptionNew)
                    || userData.isDescriptionSpicy
                    )
                  || (!userData.descriptionEdit.description && userData.descriptionEdit.descriptionNew)
                ">
                  <Badge tagName="span" variant="badgeInfo">
                    {{ trans('LABEL:DescriptionNewVersionRequiresApproval')}}
                  </Badge>
                </template>
                <template v-if="userData.descriptionEdit.descriptionRejected
                  && descriptionNewBeforeUpdate === userData.descriptionEdit.descriptionNew"
                >
                  <Badge v-if="userData.descriptionStatus.modalMessage"
                    tagName="span" :variant="userData.descriptionStatus.color"
                  >
                    {{ trans(userData.descriptionStatus.modalMessage)}}
                  </Badge>
                </template>
                <template v-if="!userData.descriptionEdit.description && !userData.descriptionEdit.descriptionNew">
                  <Badge tagName="span" :variant="'badgeError'">
                    {{ trans('LABEL:DescriptionNone') }}
                  </Badge>
                </template>
                <template v-if="userData.descriptionEdit.descriptionNew
                  &&  stringLength(userData.descriptionEdit.descriptionNew) < configData.minDescriptionLength"
                >
                  <Badge tagName="span" :variant="'badgeError'">
                    {{ trans('LABEL:DescriptionTooShort') }}
                  </Badge>
                </template>
              </BadgeFrame>
            </div>
          </div>
        </div>
        <Hint variant="Info" v-if="!(userData.description || userData.descriptionNew) || isHintDisplayed" class="my-3 font-normal">
          <div class="w-full flex flex-col lg:flex-row gap-3">
            <div class="lg:w-1/2" v-html="transChoice('TOOLTIP:DescriptionInformation1',configData.userDescriptionLimitMinCHR)"></div>
            <div class="lg:w-1/2" >
              <div class="w-full flex flex-col gap-3">
                <div v-html="trans('TOOLTIP:DescriptionInformation2')"></div>
              </div>
            </div>
          </div>
        </Hint>
      </template>
      <template v-slot:content>
        <DescriptionForm
          :send-request="sendRequestStatus"
          :send-confirmed="sendConfirmed"
          :hide-current-desc="hideCurrentDescState"
          :profile-data="userData"
          :new-description-pending="userData.descriptionNewPending"
          @disabledConfirm="isConfirmDisabled = $event"
        />
      </template>
    </Modal>

    <!--  Empty description modal  -->
    <Modal
      v-if="emptyDescriptionModal"
      :show-modal="emptyDescriptionModal"
      @clicked="manageEmptyDescriptionModal"
      @onConfirm="setSendConfirmedAction(true)"
      :header="trans('MODAL_TITLE:Attention')"
    >
      <template v-slot:content>
        <div class="flex flex-row items-center gap-2 mb-3">
          <span>{{ trans('MODAL_DESCRIPTION:EmptyDescription') }}</span>
          <Button
              v-if="!userData.description"
              variant="link"
              @clicked="toggleHint"
          >
            <base-icon  name="info">
            </base-icon>
          </Button>
        </div>
      </template>
    </Modal>

    <!--  EXPERIENCES MODAL  -->
    <Modal
      variant="experiences"
      v-if="dataEditModals.experiencesModal"
      :show-modal="dataEditModals.experiencesModal"
      @clicked="openExperiencesModal(false)"
      @onConfirm="editRequest"
      :header="trans('MODAL_TITLE:Experiences')"
      :loading="profileUpdating"
    >
      <template v-slot:content>
        <ExperiencesForm :is-mobile="isMobile" :send-request="sendRequestStatus" :user-data="userData" />
      </template>
    </Modal>

    <Modal
        hide-footer
        variant="post"
        v-if="isShowBeforeFillingYourProfileModal"
        :show-modal="isShowBeforeFillingYourProfileModal"
        @clicked="openBeforeFillingYourProfileModal(false)"
    >
      <template #content>
        <PostShow slug="before-filling-your-profile" />
      </template>
    </Modal>

  </PageContainer>
</template>

<script>

import PetForm from "@/views/content/profile/components/forms/PetForm.vue";
import AppearanceForm from "@/views/content/profile/components/forms/AppearanceForm";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import BaseIcon from "@/utils/icons/BaseIcon";
import BasicInformationForm from "@/views/content/profile/components/forms/BasicInformationForm";
import Button from "@/utils/crud/components/Button";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import DescriptionForm from "@/views/content/profile/components/forms/DescriptionForm";
import {getWidth} from "@/common/helpers/utils";
import ExperiencesForm from "@/views/content/profile/components/forms/ExperiencesForm";
import Filters from "@/views/content/filters/Filters";
import GalleryProfile from "@/views/content/profile/components/gallery/GalleryShow";
import Hint from "@/views/content/components/Hint";
import Loader from "@/views/Loader.vue";
import LocationForm from "@/views/content/profile/components/forms/LocationForm";
import LookingForForm from "@/views/content/profile/components/forms/LookingForForm";
import Modal from "@/utils/modal/Modal";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SearchingWhatsForm from "@/views/content/profile/components/forms/SearchingWhatsForm";
import StatusForm from "@/views/content/profile/components/forms/StatusForm";
import StimulantForm from "@/views/content/profile/components/forms/StimulantForm.vue";
import themeConfig from "@/themeConfig";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {stringLength, stripTags, getHeightOfScreenWidth} from "@/common/helpers/utils";
import PageContentWithSidebar from "@/layouts/components/pages/PageContentWithSidebar"
import Sidebar from "@/layouts/components/pages/Sidebar"
import PostShow from "@/views/content/posts/PostShow";
import api from "@/common/services/api";

export default {
  name: "ProfileOwner",
  components: {
    PetForm,
    AppearanceForm,
    Badge,
    BadgeFrame,
    BaseIcon,
    BasicInformationForm,
    Button,
    DescriptionForm,
    ExperiencesForm,
    Filters,
    GalleryProfile,
    Hint,
    Loader,
    LocationForm,
    LookingForForm,
    Modal,
    PageContainer,
    PageContentWithSidebar,
    PageTitle,
    SearchingWhatsForm,
    Sidebar,
    StatusForm,
    StimulantForm,
    PostShow
  },
  data() {
    return {
      dataEditModals: {
        basicInformationModal: false,
        appearanceModal: false,
        experiencesModal: false,
        statusModal: false,
        lookingForModal: false,
        searchingWhatsModal: false,
        locationModal: false,
        descriptionModal: false,
        stimulantsModal: false,
        petsModal: false,
      },
      descriptionNewBeforeUpdate:'',
      emojiIcons: themeConfig.icons.emojis,
      errorIcon: themeConfig.icons.error,
      getWidth:getWidth,
      getHeightOfScreenWidth:getHeightOfScreenWidth,
      hideCurrentDescState: false,
      iconEdit: themeConfig.icons.edit,
      isConfirmDisabled: false,
      isHintDisplayed: false,
      stringLength: stringLength,
      stripTags:stripTags,
	    profilePicturesGalleryCounter: 0,
	    galleryOnceLoaded: false,
      isShowBeforeFillingYourProfileModal: true
    }
  },
  computed: {
    ...mapGetters('profile', [ 'profileLoaded', 'sendRequestStatus', 'profileUpdated', 'profileUpdating', 'emptyDescriptionModal', 'sendConfirmed', 'profileLoading']),
	  ...mapGetters('gallery', ['galleryData']),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('loader', ['loading']),
	  ...mapGetters('utils', ['picturesLoadingCounter']),

    isMobile() {
      return checkIsMobileDevice();
    },
    isAnyExperience() {
      return this.userData.experiences['known'].length > 0 || this.userData.experiences['curious'].length > 0
    },
    areBasicFilled() {
      return this.userData.preference.id && this.userData.orientation.id;
    },
    areAppearanceFilled() {
      return this.userData.height.value && this.userData.weight.value && this.userData.shape.id;
    },
    areStatusesFilled() {
      return this.userData.statusMarital.id && this.userData.statusParental.id;
    },
	  areStimulantsFilled() {
		  return this.userData.approachDrinking.id && this.userData.approachSmoking.id && this.userData.approachDrugging.id;
	  },
    arePetsFilled() {
      return this.userData.approachPetting.id;
    }
  },
  watch: {
    profileUpdated(val) {
      if(val) {
        Object.keys(this.dataEditModals).forEach((key) => {
          if(this.dataEditModals[key] === true) {
            this.$set(this.dataEditModals, key, false);
          }
        });
	      this.fetchDashboardData();
      }
    },
	  profileLoaded: {
			immediate: true,
		  handler(val) {
				if(val) {
					this.descriptionNewBeforeUpdate = this.userData.descriptionEdit.descriptionNew;
				}
		  }
	  },
	  langLocale() {
		  this.fetchUserGallery();
	  },
	  authDone: {
		  immediate: true,
		  handler() {
			  this.fetchUserGallery();
		  }
	  },
	  picturesLoadingCounter(val) {
			if(val === 0 && !this.galleryOnceLoaded) {
				this.galleryOnceLoaded = true;
				this.setPicturesLoadingCounter(null);
      }
	  }
  },

  methods: {
    ...mapMutations('profile', ['setSendRequestStatus', 'openEmptyDescriptionModal', 'setSendConfirmed', 'setProfileData', 'setLoaded', 'setSendRequestStatus', 'openEmptyDescriptionModal']),
	  ...mapMutations('utils', ['setPicturesLoadingCounter']),

	  ...mapActions('dashboard', ['fetchDashboardData']),
	  ...mapActions('gallery', ['fetchUserGallery']),

    editRequest() {
      this.setSendRequestStatus(true);
    },

    manageEmptyDescriptionModal() {
      this.setSendRequestStatus(false);
      this.openEmptyDescriptionModal(false);
    },

    openBasicEditModal(value) {
      this.dataEditModals.basicInformationModal = value;
    },

    openAppearanceModal(value) {
      this.dataEditModals.appearanceModal = value;
    },

    openExperiencesModal(value) {
      this.dataEditModals.experiencesModal = value;
    },

    openStatusModal(value) {
      this.dataEditModals.statusModal = value;
    },

    openStimulantsModal(value) {
      this.dataEditModals.stimulantsModal = value;
    },

    openPetsModal(value) {
      this.dataEditModals.petsModal = value;
    },

    openLookingForModal(value) {
      this.dataEditModals.lookingForModal = value;
    },

    openSearchingWhatsModal(value) {
      this.dataEditModals.searchingWhatsModal = value;
    },

    openLocationModal(value) {
      this.dataEditModals.locationModal = value;
    },

    openDescriptionModal(value) {
      this.dataEditModals.descriptionModal = value;
    },

    setSendConfirmedAction() {
      this.setSendConfirmed(true);
      this.openEmptyDescriptionModal(false);
    },

    stringContainsString(string1, string2) {
      if (string1 && string2) {
        return String(stripTags(string1).replace(/\s+/g, '')).includes(stripTags(string2).replace(/\s+/g, ''))
      } else {
        return true;
      }
    },

    toggleHint() {
      this.isHintDisplayed = !this.isHintDisplayed;
    },

    openBeforeFillingYourProfileModal(value) {
      if (value === false && !this.userData.modalDisplayedProfile) {
        this.sendModalDisplayedProfileRequest();
      }
      this.isShowBeforeFillingYourProfileModal = value;
    },

    sendModalDisplayedProfileRequest() {
      api.post(`/modal-displayed-profile`)
        .then(
          success => {
            this.setUserModalDisplayedProfile()
          },
          error => {
          }
        )
    },

  },

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},

	beforeDestroy() {
		this.setProfileData({});
		this.setLoaded(false);
	},

  mounted() {
		this.fetchDashboardData();
  },

	beforeMount() {
		this.isShowBeforeFillingYourProfileModal = !this.userData.isSetupAndDescriptionComplete && !this.userData.modalDisplayedProfile;
	}

}

</script>
